import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled, { keyframes, css } from "styled-components";
import BanyaLogo from "../assets/img/banya_main_logo_light.svg";
import TranslationIcon from "../assets/img/translate.svg";
import HeroPreview1 from "../assets/img/hero_preview_1.png";
import HeroPreview2 from "../assets/img/hero_preview_2.png";
import Summary1 from "../assets/img/summary_customize.png";
import Summary2 from "../assets/img/summary_llm.png";
import Summary3 from "../assets/img/summary_gpt.png";
import Summary4 from "../assets/img/summary_speech.png";
import ToolTest from "../assets/img/tool_test.svg";
import ToolData from "../assets/img/tool_databox.svg";
import ToolDesign from "../assets/img/tool_design.svg";
import ToolSetupAi from "../assets/img/tool_setup_ai.svg";
import ChatbotComponent1 from "../assets/img/chatbot_component_1.png";
import ChatbotComponent2 from "../assets/img/chatbot_component_2.png";
import ChatbotComponent3 from "../assets/img/chatbot_component_3.png";
import TemplatePC from "../assets/img/template_pc.png";
import TemplateMobile from "../assets/img/template_mobile.png";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 2rem;
  background-color: #fff;
  @media screen and (max-width: 480px) {
    /** 모바일 세로 */
  }

  @media screen and (max-width: 768px) {
    /** 모바일 가로, 타블렛 세로 */
  }

  @media screen and (max-width: 1024px) {
    /** 타블렛 가로, 노트북 */
  }

  @media screen and (max-width: 1280px) {
    /** 타블렛 가로, 노트북 */
  }

  @media screen and (min-width: 1281px) {
    /** 데스크탑 */
  }
`;

const LogoNavWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Logo = styled.img`
  width: 7.5rem;
`;

const Nav = styled.nav`
  a {
    margin: 0 20px;
    text-decoration: none;
    color: #4d4d4d;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const AuthLinks = styled.div`
  display: flex;
  div {
    margin-left: 20px;
    color: #4d4d4d;
    font-weight: bold;
    text-decoration: none;
  }
  img {
    padding: 0 1rem;
  }
`;

const Title = styled.h1`
  font-size: 2.8rem;
  color: ${(props) => (props.dark ? "#fff" : "#0a0f0f")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "4.5rem")};
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 2.3rem;
    margin-bottom: ${(props) => (props.mb ? props.mb : "3rem")};
  }
  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  color: #af8aff;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.mt ? props.mt : 0)};
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Hero = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6.2rem;
  background-color: #f5f5f5;

  .new-button {
    display: none;
  }
  @media screen and (max-width: 1280px) {
    flex-direction: column;
    .new-button {
      display: block;
      font-weight: 400;
      padding: 0.7rem 1rem;
      background-color: #5133bb;
      color: #fff;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 4rem;
    .new-button {
      font-size: 1.3rem;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 4rem 3rem;
    .new-button {
      font-size: 1.2rem;
    }
  }
`;

const HeroContent = styled.div`
  color: #262628;

  h1 {
    font-size: 6rem;
    height: 6rem;
  }

  h2 {
    font-size: 4rem;
    margin-right: 1rem;
  }

  .paragraph-wrapper {
    margin: 2.3rem 1rem 7.5rem 0;
  }

  p {
    font-size: 1.75rem;
    font-weight: "regular";
    color: #4d4d4d;
    margin: 0 0 0.5rem 0;
  }

  button {
    font-weight: 400;
    padding: 15px 30px;
    background-color: #5133bb;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.875rem;
  }

  @media screen and (max-width: 1280px) {
    h1 {
      font-size: 5rem;
      height: auto;
    }
    h2 {
      font-size: 4rem;
      height: auto;
    }
    .paragraph-wrapper {
      margin: 2.3rem 1rem 4rem 0;
    }
    button {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 4rem;
    }
    h2 {
      font-size: 3rem;
    }
    .paragraph-wrapper {
      margin: 2rem 0;
    }
    p {
      font-size: 1.4rem;
    }
  }
  @media screen and (max-width: 480px) {
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2rem;
    }
    .paragraph-wrapper {
      margin: 2rem 0;
    }
    p {
      font-size: 1.2rem;
      margin: 0 0 0.3rem 0;
    }
  }
`;

const HeroImage = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 31.5rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
  }

  @media screen and (max-width: 1280px) {
    img {
      width: 100%;
      justify-content: center;
    }
  }
`;

const Summary = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6.25rem;
  background-color: #0b0f10;
  @media screen and (max-width: 768px) {
    padding: 6.25rem 3rem;
  }
`;

const SummaryTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SummaryWrapper = styled.div`
  display: flex;
  align-items: center;

  .mobile {
    display: none;
  }
  @media screen and (max-width: 1280px) {
    flex-direction: column;
    .pc {
      display: none;
    }
    .mobile {
      color: #fff;
      display: block;
      font-size: 1.5rem;
      padding: 3rem 0 1.5rem 0;
    }
  }
  @media screen and (max-width: 768px) {
    .mobile {
      color: #fff;
      display: block;
      font-size: 1.5rem;
      padding: 3rem 0 1.5rem 0;
    }
  }
`;

// 색상 채우기 애니메이션 정의
const fillAnimation = keyframes`
  0% {
    color: transparent;
  }
  100% {
    color: #8D6CD5;
  }
`;

// 텍스트에 애니메이션을 적용하는 Styled Component
const AnimatedText = styled.p`
  font-size: 1.2rem;
  margin: 10px 0;
  color: ${({ isActive }) => (isActive ? "transparent" : "#fff")};
  ${({ isActive, duration }) =>
    isActive &&
    css`
      animation: ${fillAnimation} ${duration}s forwards;
    `}
`;

// 1280px 이하에서 텍스트 애니메이션 적용
const AnimatedTextMobile = styled.p`
  display: none;
  font-size: 18px;
  margin: 10px 0;

  @media screen and (max-width: 1280px) {
    display: block;
    text-align: center;
    margin: 0;
    font-size: 1.3rem;
    padding: 1rem 0;
    color: #fff;
    /* animation: ${fillAnimation} 2s forwards; */
  }
  @media screen and (max-width: 480px) {
    display: block;
    margin: 0;
    color: #fff;
    font-size: 1rem;
    /* animation: ${fillAnimation} 2s forwards; */
  }
`;

const SummaryPreview = styled.div`
  width: 52rem;
  height: 32rem;
  border-radius: 1.75rem;
  background-color: #f2f4ff;
  display: inline-flex;
  justify-content: center;
  align-items: end;
  margin: 0 4rem;
  img {
    width: 40rem;
    border-radius: 1rem 1rem 0 0;
  }
  @media screen and (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 2rem 0 0 0;
    img {
      width: 90%;
      margin: 0 1rem;
      border-radius: 1rem 1rem 0 0;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 1rem 0 0 0;
  }
`;

const SummaryContent = styled.ul`
  display: flex;
  flex-direction: column;
  height: 20rem;
  justify-content: space-between;
  /* p {
    text-decoration: none;
    color: ${(props) => (props.selected ? "#BB8AFF" : "#fff")};
    font-size: 1.5rem;
  } */
  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

const Features = styled.section`
  padding: 7.5rem 6.25rem 12.5rem 6.25rem;
  background-color: #fff;
  text-align: center;

  h2 {
    font-size: 36px;
    color: #262628;
  }
  @media screen and (max-width: 768px) {
    padding: 5.5rem 3rem 8.5rem 3rem;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  margin: 0 10vw;
  gap: 1.3rem;
  @media screen and (max-width: 1024px) {
    margin: 0;
  }
  @media screen and (max-width: 768px) {
    margin: 0;
    grid-template-columns: 0.9fr;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  background-color: #262628;
  color: #fff;
  padding: 1.8rem 1.5rem;
  border-radius: 1rem;

  .icon-wrapper {
    padding: 0.8rem;
    border-radius: 0.8rem;
    background-color: #fff;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    padding-left: 1rem;
  }
  .title {
    font-size: 1.6rem;
    font-weight: 700;
    padding-bottom: 0.8rem;
  }
  .subtitle {
    font-size: 1.1rem;
    color: #cacaca;
  }
  img {
    width: 2.5rem;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 1.4rem;
      font-weight: 700;
      padding-bottom: 0.8rem;
    }
    .subtitle {
      font-size: 1.1rem;
      color: #cacaca;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 1rem;
    .title {
      font-size: 1.2rem;
      font-weight: 700;
      padding-bottom: 0.8rem;
    }
    .subtitle {
      font-size: 0.9rem;
      color: #cacaca;
    }
  }
`;

const Component = styled.section`
  padding: 7.5rem 6.25rem 12.5rem 6.25rem;
  background-color: #0b0f10;
  text-align: center;
  @media screen and (max-width: 1024px) {
    padding: 5.5rem 3rem 8.5rem 3rem;
  }
  @media screen and (max-width: 480px) {
    padding: 5.5rem 2rem 8.5rem 2rem;
  }
`;

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
`;

const ComponentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    padding-top: 1rem;
    font-weight: 400;
  }
`;

const ComponentPreviewBackground = styled.div`
  width: 48rem;
  height: 28rem;
  border-radius: 1.625rem;
  background-color: #f2f4ff;
  display: flex;
  justify-content: center;
  align-items: end;
  margin: 0 1rem;

  img {
    width: 40rem;
    border-radius: 1rem 1rem 0 0;
  }
  /* 스크롤바 스타일 */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    background: #8d6cd5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8d6cd5;
    border-radius: 10px;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    padding-top: 2rem;
    margin: 0;
    img {
      width: 100%;
    }
    .title {
      padding: 1rem 0;
      color: #333;
    }
  }
`;

const TemplateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1700px) {
    flex-direction: column;
  }
`;

const TemplatePreview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    padding: 1rem;
    font-weight: 400;
    color: #fff;
  }
`;

const TemplatePreviewItem = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "100%")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  height: 32rem;

  border-radius: 1.625rem;
  background-color: #f2f4ff;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  color: #fff;

  img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }
  @media screen and (max-width: 1024px) {
    height: 100%;
    padding: 1rem;
  }
`;

const Pricing = styled.section`
  padding: 7.5rem 6.25rem 11.5rem 6.25rem;
  background-color: #f5f5f5;
  text-align: center;
  @media screen and (max-width: 1280px) {
    padding: 5.5rem 6.25rem 11.5rem 6.25rem;
  }
`;

const PricingCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

const PricingCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 1.2rem;
  border-radius: 10px;
  width: 23rem;
  height: 30rem;
  padding: 3rem 2.5rem 2rem 2.5rem;
  border: ${(props) => (props.selected ? "#8D6CD5 solid 2px" : "none")};
  background-color: ${(props) => (props.selected ? "#F2F4FF" : "#fff")};
  color: #333333;

  .label {
    display: flex;
    justify-content: center;
    align-self: start;
    padding: 0.3rem 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    color: ${(props) => (props.selected ? "#8D6CD5" : "#333333")};
    background-color: ${(props) => (props.selected ? "#fff" : "#F3F3F3")};
  }
  .price-wrapper {
    display: flex;
    align-items: baseline;
  }
  .price {
    font-size: 2.125rem;
    font-weight: 700;
  }
  .price-per {
    font-size: 1.3rem;
    font-weight: 700;
    padding-left: 0.5rem;
  }
  .description {
    color: #666666;
    text-align: start;
    padding: 1rem 0;
  }
  .button {
    width: 100%;
    font-size: 1.25rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    border-radius: 8px;
    color: ${(props) => (props.selected ? "#fff" : "#333333")};
    background-color: ${(props) => (props.selected ? "#8D6CD5" : "#F3F3F3")};
  }
  ul {
    padding: 1.5rem 0.5rem 0 1rem;
  }
  li {
    text-align: start;
    color: #666666;
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 1280px) {
    margin: 0 0 1rem 0;
  }
  @media screen and (max-width: 480px) {
  }
`;

const Demo = styled.section`
  padding: 7.5rem 6.25rem 5rem 6.25rem;
  background-color: #262628;
  color: #fff;
  text-align: center;
  p {
    font-size: 1.75rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 768px) {
    padding: 5.5rem 3rem;
    p {
      font-size: 1.3rem;
    }
  }
`;

const DemoVideo = styled.div`
  iframe {
    width: 100%;
    height: 686px;

    @media screen and (max-width: 1280px) {
      width: 100%;
      height: 608px;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 464px;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 378px;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      height: 168px;
    }
  }
`;

const Footer = styled.footer`
  display: flex;
  background-color: #f9f9f9;
  padding: 2rem 4rem;
  text-align: left;
  color: #555;
  div {
    font-size: 0.75rem;
  }
  .section-0 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .section-1 {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    align-items: start;
    div {
    }
  }
  .section-2 {
    padding-top: 1rem;
  }
  .section-3 {
    display: flex;
    gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .section-0 {
      flex-direction: column;
    }
    .section-1 {
      padding: 1rem 0;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 2rem;
  }
`;

const Landing = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation("landing");
  const [selected, setSelected] = useState(0);
  const [currentPreview, setCurrentPreview] = useState(0);

  const navigate = useNavigate();

  const changeLanguage = () => {
    const newLanguage = i18n.language === "ko" ? "en" : "ko";
    i18n.changeLanguage(newLanguage);
  };

  const handleClick = () => {
    alert(t("alert.inProgress"));
  };

  const contentData = [
    { id: 0, image: Summary1, text: t("summary.items.0") },
    { id: 1, image: Summary2, text: t("summary.items.1") },
    { id: 2, image: Summary3, text: t("summary.items.2") },
    { id: 3, image: Summary4, text: t("summary.items.3") },
  ];

  const featuresData = [
    {
      id: 0,
      image: ToolTest,
      title: t("features.items.0.title"),
      description: t("features.items.0.description"),
    },
    {
      id: 1,
      image: ToolDesign,
      title: t("features.items.1.title"),
      description: t("features.items.1.description"),
    },
    {
      id: 2,
      image: ToolData,
      title: t("features.items.2.title"),
      description: t("features.items.2.description"),
    },
    {
      id: 3,
      image: ToolSetupAi,
      title: t("features.items.3.title"),
      description: t("features.items.3.description"),
    },
  ];

  const componentData = [
    { id: 0, image: ChatbotComponent1, text: t("components.items.0") },
    { id: 1, image: ChatbotComponent2, text: t("components.items.1") },
    { id: 2, image: ChatbotComponent3, text: t("components.items.2") },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPreview((prev) => (prev + 1) % contentData.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [contentData.length]);

  return (
    <>
      <HeaderContainer>
        <LogoNavWrapper>
          <Logo src={BanyaLogo} alt="Banya Logo" />
          <Nav>
            <a href="#product">{t("header.product")}</a>
            <a href="#features">{t("header.features")}</a>
            <a href="#pricing">{t("header.pricing")}</a>
            <a href="#demo">{t("header.demo")}</a>
          </Nav>
        </LogoNavWrapper>
        <AuthLinks>
          <div onClick={handleClick}>{t("header.login")}</div>
          <div onClick={handleClick}>{t("header.getStarted")}</div>
          <img src={TranslationIcon} alt="translate" onClick={changeLanguage} />
        </AuthLinks>
      </HeaderContainer>

      {/* 히어로 Section */}
      <Hero>
        <HeroContent>
          <h1>{t("hero.title")}</h1>
          <h2>{t("hero.subtitle")}</h2>
          <div className="paragraph-wrapper">
            <p>{t("hero.description1")}</p>
            <p>{t("hero.description2")}</p>
          </div>
          <button onClick={() => navigate("/project")}>
            {t("hero.button")}
          </button>
        </HeroContent>
        <HeroImage>
          <img src={HeroPreview1} alt={"preview1"} />
          <img src={HeroPreview2} alt={"preview2"} />
        </HeroImage>
        <button className="new-button" onClick={() => navigate("/project")}>
          {t("hero.button")}
        </button>
      </Hero>

      {/* 요약 Section */}
      <Summary>
        <SummaryTitle>
          <Subtitle>{t("summary.subtitle")}</Subtitle>
          <Title dark={true}>{t("summary.title")}</Title>
        </SummaryTitle>
        <SummaryWrapper>
          {window.innerWidth <= 1280 && (
            <AnimatedTextMobile>
              {contentData[currentPreview].text}
            </AnimatedTextMobile>
          )}
          <SummaryPreview>
            <img
              src={contentData[currentPreview].image}
              alt={contentData[currentPreview].text}
            />
          </SummaryPreview>
          <SummaryContent>
            {contentData.map((item) => (
              <AnimatedText
                key={item.id}
                isActive={currentPreview === item.id}
                duration={2}
              >
                {item.text}
              </AnimatedText>
            ))}
          </SummaryContent>
        </SummaryWrapper>
      </Summary>

      {/* 기능과 성능 Section */}
      <Features id="features">
        <Subtitle>{t("features.subtitle")}</Subtitle>
        <Title>{t("features.title")}</Title>
        <FeaturesGrid>
          {featuresData.map((item) => (
            <FeatureItem key={item.id}>
              <div className="icon-wrapper">
                <img src={item.image} alt="" />
              </div>
              <div className="text-wrapper">
                <p className="title">{item.title}</p>
                <p className="subtitle">{item.description}</p>
              </div>
            </FeatureItem>
          ))}
        </FeaturesGrid>
      </Features>

      {/* 컴포넌트 Section */}
      <Component>
        <Subtitle>{t("components.subtitle")}</Subtitle>
        <Title dark={true}>{t("components.title")}</Title>
        <ComponentWrapper>
          {window.innerWidth <= 1024 ? (
            <ComponentPreviewBackground>
              {componentData.map((item) => (
                <div key={item.id}>
                  <img src={item.image} alt={item.text} />
                  <div className="title">{item.text}</div>
                </div>
              ))}
            </ComponentPreviewBackground>
          ) : (
            componentData.map((item) => (
              <ComponentTitleWrapper key={item.id}>
                <ComponentPreviewBackground>
                  <img src={item.image} alt={item.text} />
                </ComponentPreviewBackground>
                <div className="title">{item.text}</div>
              </ComponentTitleWrapper>
            ))
          )}
        </ComponentWrapper>
        <Subtitle dark={true} mt={window.innerWidth <= 768 ? "6rem" : "10rem"}>
          {t("templates.subtitle")}
        </Subtitle>
        {t("templates.title")}
        <Title dark={true}></Title>
        <TemplateWrapper>
          <TemplatePreview>
            <TemplatePreviewItem
              width={window.innerWidth > 1024 ? "62rem" : ""}
              padding={"2rem"}
            >
              <img src={TemplatePC} alt={t("templates.items.0")} />
            </TemplatePreviewItem>
            <div className="title">{t("templates.items.0")}</div>
          </TemplatePreview>
          <TemplatePreview>
            <TemplatePreviewItem
              width={window.innerWidth > 1024 ? "35rem" : ""}
              padding={"2rem"}
            >
              <img src={TemplateMobile} alt={t("templates.items.0")} />
            </TemplatePreviewItem>
            <div className="title">{t("templates.items.1")}</div>
          </TemplatePreview>
        </TemplateWrapper>
      </Component>

      <Pricing id="pricing">
        <Subtitle>{t("pricing.subtitle")}</Subtitle>
        <Title>{t("pricing.title")}</Title>
        <PricingCards>
          <PricingCard selected={selected === 0} onClick={() => setSelected(0)}>
            <div className="label">{t("pricing.plans.0.name")}</div>
            <div className="price">{t("pricing.plans.0.price")}</div>
            <div className="description">
              {t("pricing.plans.0.description")}
            </div>
            <div className="button">{t("pricing.plans.0.button")}</div>
            <ul>
              <li>{t("pricing.plans.0.features.0")}</li>
              <li>{t("pricing.plans.0.features.1")}</li>
              <li>{t("pricing.plans.0.features.2")}</li>
              <li>{t("pricing.plans.0.features.3")}</li>
            </ul>
          </PricingCard>
          <PricingCard selected={selected === 1} onClick={() => setSelected(1)}>
            <div className="label">{t("pricing.plans.1.name")}</div>
            <div className="price">{t("pricing.plans.1.price")}</div>
            <div className="description">
              {t("pricing.plans.1.description")}
            </div>
            <div className="button">{t("pricing.plans.1.button")}</div>
            <ul>
              <li>{t("pricing.plans.1.features.0")}</li>
              <li>{t("pricing.plans.1.features.1")}</li>
              <li>{t("pricing.plans.1.features.2")}</li>
              <li>{t("pricing.plans.1.features.3")}</li>
            </ul>
          </PricingCard>
          <PricingCard selected={selected === 2} onClick={() => setSelected(2)}>
            <div className="label">{t("pricing.plans.2.name")}</div>
            <div className="description">
              {t("pricing.plans.2.description")}
            </div>
            <div className="button">{t("pricing.plans.2.button")}</div>
            <ul>
              <li>{t("pricing.plans.2.features.0")}</li>
              <li>{t("pricing.plans.2.features.1")}</li>
              <li>{t("pricing.plans.2.features.2")}</li>
              <li>{t("pricing.plans.2.features.3")}</li>
            </ul>
          </PricingCard>
        </PricingCards>
      </Pricing>
      <Demo id="demo">
        <Title dark={true} mb="1rem">
          {t("demo.title")}
        </Title>
        <p>{t("demo.description")}</p>
        <DemoVideo>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KnjTlqW2ymY?si=n1W7JvJGYTcNduQO"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </DemoVideo>
      </Demo>
      {/* Footer Section */}
      <Footer>
        <Logo src={BanyaLogo} />
        <div className="section-0">
          <div className="section-1">
            <div>{t("footer.company")}</div>
            <div>{t("footer.address")}</div>
            <div>{t("footer.contact")}</div>
            <div className="section-2">{t("footer.copyright")}</div>
          </div>
          <div className="section-3">
            <div>{t("footer.privacy")}</div>
            <div>{t("footer.terms")}</div>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default Landing;
