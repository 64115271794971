import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { deleteImageData, getImageData } from "../../api/DatasetApi.js";

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
`;

const Table = styled.table`
  width: 90%;
  margin-left: 10px;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  overflow: hidden;
`;

const TableHeader = styled.th`
  background-color: #3a3a3a;
  color: #f0f0f0;
  padding: 15px;
  text-align: center;
  font-size: 16px;
`;

const TableRow = styled.tr`
  background-color: #222;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
  accent-color: ${(props) => (props.selected ? "#7000FF" : "#7000FF")};
`;

const Button = styled.button`
  background-color: #333;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  margin-top: -2px;
  cursor: pointer;
  margin-left: 20px;
  height: 40px;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5a0c9e;
  }
`;

const TagButton = styled.span`
  background-color: rgba(255, 255, 255, 0.6);
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 120px;
  margin-top: 20px;
`;

const ButtonContainer2 = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 140px;
  margin-bottom: 20px;
  height: 35px;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 420px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
`;

const ModalTitle = styled.div`
  color: black;
  font-size: 20px;
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  margin-top: 20px;
`;

const ModalButton = styled.button`
  background-color: ${(props) => (props.primary ? "#5500CC" : "#ddd")};
  color: ${(props) => (props.primary ? "white" : "black")};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.primary ? "#5500aa" : "#ccc")};
  }
`;

const TableCell = styled.td`
  padding: 15px;
  border-bottom: 1px solid #444;
  color: #d4d4d4;
  font-size: 15px;
  text-align: ${(props) => (props.alignLeft ? "left" : "center")};
`;

const CheckboxCell = styled(TableCell)`
  text-align: left;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const BackButton = styled.span`
  cursor: pointer;
  margin-top: 10px;
  font-size: 40px;
  margin-right: 10px;
`;

const Breadcrumb = styled.p`
  font-size: 14px;
  color: white;
  margin: 0 0 10px 10px;
`;

const PROJECT_ID = 1;
const project_id = PROJECT_ID;
const USER_EMAIL = "first@daiblab.com";
const userEmail = USER_EMAIL;

const Imgdata = () => {
  const [imageList, setImageList] = useState([]); // 이미지를 담을 상태
  const [selectedRows, setSelectedRows] = useState({});
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // getImageData 함수 호출하여 데이터를 가져옴
    getImageData(USER_EMAIL)
      .then((res) => setImageList(res)) // 가져온 데이터를 imageList 상태에 저장
      .catch((err) => console.log(err)); // 오류 처리
  }, []); // 빈 배열로 컴포넌트가 마운트될 때 한 번만 실행되도록 설정

  const handleRowClick = (index) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [index]: !prevSelectedRows[index],
    }));
  };

  const handleAddClick = () => {
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleAddConfirm = () => {
    setIsAddModalOpen(false);
    navigate("/dataset/img/add/");
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteConfirm = async () => {
    const selectedImageIds = Object.keys(selectedRows).filter(
      (index) => selectedRows[index]
    );

    if (selectedImageIds.length > 0) {
      try {
        // 선택된 이미지 데이터에 대해 삭제 요청
        await Promise.all(
          selectedImageIds.map(async (index) => {
            const imageData = imageList[index];
            await deleteImageData(
              imageData.id, // 이미지 데이터 ID
              userEmail, // 사용자 이메일
              project_id // 프로젝트 ID
            );
          })
        );

        // 삭제 후 데이터 업데이트
        setImageList(imageList.filter((_, index) => !selectedRows[index]));
        setSelectedRows({});
        setIsDeleteModalOpen(false); // 삭제 모달 닫기
      } catch (err) {
        console.log("삭제 실패:", err);
      }
    } else {
      alert("삭제할 데이터를 선택하세요.");
    }
  };

  // Example of defining these functions
  const handleTrainingClick = () => {
    // Logic for handling training button click
  };

  return (
    <MainContent>
      <Breadcrumb
        onClick={() => navigate("/dataset")}
      >{`데이터셋함 > 이미지 데이터셋 리스트`}</Breadcrumb>
      <TitleContainer>
        <BackButton onClick={() => navigate("/dataset")}>{"<"}</BackButton>
        <Title>이미지 데이터셋 리스트</Title>
      </TitleContainer>

      <ButtonContainer2>
        <Button onClick={handleAddClick}>추가하기</Button>
        <Button onClick={handleDeleteClick}>삭제하기</Button>
      </ButtonContainer2>

      <Table>
        <thead>
          <tr>
            <TableHeader></TableHeader>
            <TableHeader>데이터셋 ID</TableHeader>
            <TableHeader>태그</TableHeader>
            <TableHeader>생성일</TableHeader>
            <TableHeader>크기(KByte)</TableHeader>
            <TableHeader>내용보기</TableHeader>
          </tr>
        </thead>
        <tbody>
          {imageList.map((item, index) => (
            <TableRow key={index} onClick={() => handleRowClick(index)}>
              <CheckboxCell alignLeft>
                <Checkbox
                  type="checkbox"
                  checked={!!selectedRows[index]}
                  onChange={() => handleRowClick(index)}
                />
              </CheckboxCell>
              <TableCell>{item.id}</TableCell> {/* 데이터셋 ID */}
              <TableCell>
                {item.img_tag.split(",").map((tag, idx) => (
                  <TagButton key={idx}>{tag}</TagButton> // 태그 표시
                ))}
              </TableCell>
              <TableCell>{item.created_date}</TableCell> {/* 생성일 */}
              <TableCell>{(item.size / 1024).toFixed(2)} KByte</TableCell>{" "}
              {/* 크기 (KB) */}
              <TableCell>
                <Button onClick={() => navigate(`/dataset/view/${item.id}`)}>
                  내용보기
                </Button>
              </TableCell>{" "}
              {/* 내용보기 버튼 */}
            </TableRow>
          ))}
        </tbody>
      </Table>
      <ButtonContainer>
        <Button onClick={handleTrainingClick}>학습하기</Button>
      </ButtonContainer>

      {/* Add Confirmation Modal */}
      {isAddModalOpen && (
        <ModalBackdrop>
          <ModalContainer>
            <ModalTitle>새로운 데이터셋을 추가하시겠습니까?</ModalTitle>
            <ModalButtonContainer>
              <ModalButton primary onClick={handleAddConfirm}>
                예
              </ModalButton>
              <ModalButton onClick={handleCloseAddModal}>아니오</ModalButton>
            </ModalButtonContainer>
          </ModalContainer>
        </ModalBackdrop>
      )}

      {isWarningModalOpen && (
        <ModalBackdrop>
          <ModalContainer>
            <ModalTitle>학습시킬 데이터를 선택해주세요</ModalTitle>
            <ModalButtonContainer>
              <ModalButton primary onClick={() => setIsWarningModalOpen(false)}>
                확인
              </ModalButton>
            </ModalButtonContainer>
          </ModalContainer>
        </ModalBackdrop>
      )}

      {isDeleteModalOpen && (
        <ModalBackdrop>
          <ModalContainer>
            <ModalTitle>선택한 데이터를 삭제하시겠습니까?</ModalTitle>
            <ModalButtonContainer>
              <ModalButton primary onClick={handleDeleteConfirm}>
                예
              </ModalButton>
              <ModalButton onClick={handleCloseDeleteModal}>아니오</ModalButton>
            </ModalButtonContainer>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </MainContent>
  );
};

export default Imgdata;
