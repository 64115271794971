import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Doughnut, Bar } from "react-chartjs-2";
import "chart.js/auto"; // 이 라인을 추가합니다.

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
`;

const ProjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #222;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
`;

const ProjectDetail = styled.p`
  margin: 10px 0;
  margin-left: 10px;
  line-height: 1.8;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ProjectInfoContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 20px;
  height: 120px;
`;

const ProjectDetail2 = styled.div`
  flex: 1;
  margin-top: 10px;
  color: black;
  margin-left: 60px;
  font-size: 18px;
`;

const Heading2 = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-left: 10px;
`;

const Heading = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

const Slider = styled.input`
  flex: 1;
  margin: 0 10px;
`;

const AmountDisplay = styled.span`
  color: #fff;
`;

const CircularContainer = styled.div`
  background-color: white;
  color: black;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px;
  font-weight: bold;
  margin: 0 10px 0 20px;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
`;

const MainContent2 = styled.main`
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: 30px;
  margin-left: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 60px;
`;

const DoughnutContainer = styled.div`
  width: 300px;
  height: 300px;
  margin-left: 50px;
  position: relative;
`;

const BarContainer = styled.div`
  width: 400px;
  height: 300px;
  margin-left: 200px;
  position: relative;
`;

const InfoBox = styled.div`
  margin-top: 30px;
  margin-left: 10px;
  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InfoItem = styled.div`
  display: flex;
  gap: 10px;
  font-size: 20px;
  color: #333;
`;

const Divider = styled.hr`
  margin: 30px 0;
  border: 1px solid #dedede;
`;

const Charge = () => {
  const [amount, setAmount] = useState(5000);
  const [selectedValue, setSelectedValue] = useState(null);

  const doughnutData = {
    labels: ["직접 충전량", "현재 보유량"],
    datasets: [
      {
        data: [5500, 4500],
        backgroundColor: ["#FFA500", "#FF4500"],
        hoverBackgroundColor: ["#FFA500", "#FF4500"],
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const idx = elements[0].index;
        const value = doughnutData.datasets[0].data[idx];
        setSelectedValue(value);
      }
    },
  };

  const barData = {
    labels: ["4월", "5월", "6월", "7월", "8월", "9월"],
    datasets: [
      {
        label: "챗봇 API",
        data: [100, 150, 200, 250, 300, 400],
        backgroundColor: "#FFA500",
      },
      {
        label: "추천 API",
        data: [50, 100, 150, 200, 250, 300],
        backgroundColor: "#FF4500",
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  useEffect(() => {
    console.log("Credit 컴포넌트가 마운트되었습니다.");
  }, []);

  const handleSliderChange = (e) => {
    setAmount(e.target.value);
  };

  const actualAmount = (amount / 1.3).toFixed(0); // Calculate the actual amount

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.iamport.kr/v1/iamport.js";
    script.onload = () => {
      if (window.IMP) {
        window.IMP.init("imp04037430");
      } else {
        console.error("IMP 라이브러리 로드 실패");
      }
    };
    document.body.appendChild(script);
  }, []);

  const onClickPay = () => {
    if (window.IMP) {
      window.IMP.request_pay(
        {
          pg: "kakaopay",
          pay_method: "card",
          merchant_uid: "ORD20231030-000002",
          name: "반야 트레이너 크레딧 충전",
          amount: actualAmount,
          buyer_email: "yjin@goatfarm.ai",
          buyer_name: "김예진",
          buyer_tel: "010-1234-5678",
          buyer_addr: "경기도 수원시 영통동",
          buyer_postcode: "123-456",
        },
        function (response) {
          const { status, err_msg } = response;
          if (err_msg) {
            alert(err_msg);
          }
          if (status === "paid") {
            //const {imp_uid} = response;
            //verifyPayment(imp_uid);
          }
        }
      );
    }
  };

  return (
    <MainContent>
      <Title>크레딧 충전</Title>
      <Description>카드 등록 후 크레딧을 충전해주세요.</Description>
      <ProjectInfoContainer>
        <ProjectDetail>
          <Heading>크레딧 사용방법</Heading>
          <br />
          - 최종 사용자용 API를 생성하기 위해서는 아래의 데이터셋함에서 텍스트,
          이미지 데이터를 업로드 후 학습시킬 데이터들을 선택하여 나의 프로젝트에
          할당합니다.
          <br />- 텍스트 또는 이미지 데이터셋 리스트에서 선택한 데이터셋이 특정
          프로젝트에 할당되도록 합니다.
        </ProjectDetail>
      </ProjectInfoContainer>
      <SliderContainer>
        <AmountDisplay>{amount} 크레딧</AmountDisplay>
        <Slider
          type="range"
          min="0"
          step="10"
          max="1000000"
          value={amount}
          onChange={handleSliderChange}
          style={{ accentColor: "red" }}
        />
        <AmountDisplay>1,000,000</AmountDisplay>
        <CircularContainer>{actualAmount} 원</CircularContainer>
        <Button onClick={onClickPay}>결제요청</Button>
        <script src="https://cdn.iamport.kr/v1/iamport.js"></script>
        <script src="/main.js"></script>
      </SliderContainer>
      <ProjectInfoContainer2>
        <FlexContainer>
          <ProjectDetail2>
            <Heading2>충전 단위</Heading2>
            <br />° 10 크레딧 = 13원
          </ProjectDetail2>
          <ProjectDetail2>
            <Heading2>멀티모달 AI</Heading2>
            <br />
            ° API 1회 호출 = 1 크레딧
            <br />° 학습 1회 = ? 크레딧
          </ProjectDetail2>
          <ProjectDetail2>
            <Heading2>AI 추천 시스템</Heading2>
            <br />
            ° API 1회 호출 = 1 크레딧
            <br />° 학습 1회 = ? 크레딧
          </ProjectDetail2>
        </FlexContainer>
      </ProjectInfoContainer2>
      <Divider />
      <Title>크레딧 사용현황</Title>
      <Description>나의 크레딧 사용현황을 분석해드려요.</Description>
      <InfoBox>
        <InfoItem>
          <span style={{ color: "#FFA500" }}>직접 충전량:</span>
          <span style={{ color: "white" }}>5,500</span>
        </InfoItem>
        <InfoItem>
          <span style={{ color: "#FF4500" }}>현재 보유량:</span>
          <span span style={{ color: "white" }}>
            4,500
          </span>
        </InfoItem>
      </InfoBox>
      <MainContent2>
        <ChartContainer>
          <DoughnutContainer>
            <Doughnut data={doughnutData} options={doughnutOptions} />
            {selectedValue !== null && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "24px",
                  color: "#333",
                }}
              >
                {selectedValue}
              </div>
            )}
          </DoughnutContainer>
          <BarContainer>
            <Bar data={barData} options={barOptions} />
          </BarContainer>
        </ChartContainer>
      </MainContent2>
    </MainContent>
  );
};

export default Charge;
