import React, { useRef, useState, useEffect, useMemo } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  useChat,
  Header,
  MessagesContainer,
  InputContainer,
  ChatBotContainer,
  ButtonCollections,
} from "loki-banya";

const ColorCode = styled.span`
  margin-bottom: 10px;
  font-size: 20px;
  padding: 10px 10px;
`;

const ColorInput = styled.input`
  margin-top: 10px;
  width: 100px;
  height: 30px;
  border: none;
  cursor: pointer;
  background: none;
  margin-bottom: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  position: relative;
`;

const FormsContainer = styled.div`
  flex: 1;
  min-width: 0;
`;

const ChatContainer = styled.div`
  width: 600px;
  flex-shrink: 0;
  position: sticky;
  top: 200px;

  @media (max-width: 1200px) {
    width: 100%;
    position: static;
    margin-top: 10px;
  }
`;

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
  margin-bottom: 100px;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
`;

const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
`;

const ProjectForm = styled.div`
  background-color: #111;
  border-radius: 20px;
  padding: 20px;
  color: white;
  width: 1000px;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const FormField = styled.div`
  margin-bottom: 30px;
`;

const FlexContainer3 = styled.div`
  display: flex;
  width: 95%;
  height: 120px;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #555;
  padding: 15px;
  background-color: #333;
`;

const Label0 = styled.label`
  font-size: 25px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
`;

const Label2 = styled.label`
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 50px;
  position: relative;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;
`;

const InputField = styled.input`
  width: 400px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #222;
  color: white;
  transition: border-color 0.3s;

  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;

const InputField2 = styled.input`
  width: 200px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #222;
  color: white;
  transition: border-color 0.3s;

  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;

const SaveButton2 = styled.button`
  background-color: #7000ff;
  color: white;
  margin-right: 15px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5500cc;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
`;

const ModalHeader = styled.div`
  margin-bottom: 30px;
`;

const ModalButton = styled.button`
  background-color: #5500cc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background-color: #5500aa;
  }
`;

const spin = keyframes`  
  0% { transform: rotate(0deg); }  
  100% { transform: rotate(360deg); }  
`;

const Loader = styled.div`
  margin-left: 140px;
  border: 8px solid #555;
  border-top: 8px solid #7000ff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 800px;
  justify-content: flex-first;
  margin-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const BackButton = styled.span`
  cursor: pointer;
  margin-top: 10px;
  font-size: 40px;
  margin-right: 10px;
`;

const Breadcrumb = styled.p`
  font-size: 14px;
  color: white;
  margin: 0 0 10px 10px;
`;

const PROJECT_ID = 1;
const project_id = PROJECT_ID;

const Chatset = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [error] = useState("");
  const navigate = useNavigate();

  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [backgroundPadding, setBackgroundPadding] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [aiprofileColor, setAiprofileColor] = useState("transparent");
  const [downbarColor, setdownBarColor] = useState("#262628");
  const [inputbarColor, setinputBarColor] = useState("#262628");

  const [barColor, setBarColor] = useState("#262628");
  const [barWidth, setBarWidth] = useState("");
  const [barHeight, setBarHeight] = useState("");
  const [barTop, setBarTop] = useState("");
  const [barLeft, setBarLeft] = useState("");
  const [barBorder, setBarBorder] = useState("");

  const [userbubbleColor, setUserbubbleColor] = useState("#4c34b4");
  const [userfontColor, setuserFontColor] = useState("#ffffff");
  const [userborderRadius, setUserborderRadius] = useState("");
  const [usermaxWidth, setUsermaxWidth] = useState("");

  const [aibubbleColor, setAibubbleColor] = useState("#262628");
  const [aifontColor, setAiFontColor] = useState("#ffffff");
  const [aiborderRadius, setAiborderRadius] = useState("");
  const [aimaxWidth, setAimaxWidth] = useState("");
  const [aiGrid, setAiGrid] = useState("");
  const [aiProfile, setAiProfile] = useState("");

  const [buttonColor, setButtonColor] = useState("#ffffff");
  const [buttonTextColor, setButtonTextColor] = useState("#4c34b4");
  const [buttonHeight, setButtonHeight] = useState("");
  const [buttonWidth, setButtonWidth] = useState("");

  const PRIVATE_KEY = "kid";

  const {
    messages,
    connected,
    addMessage,
    addComponent,
    handleSend,
    handleButton,
  } = useChat(PRIVATE_KEY);
  const messagesContainerRef = useRef(null);

  const buttonsData = useMemo(
    () => [
      { label: "🔧 Service Usage Guide", content: "Service Usage Guide" },
      { label: "🗣️ Consulting Request", content: "Consulting Request" },
      { label: "💰 Pricing Information", content: "Pricing Information" },
      { label: "💡 Success Stories", content: "Success Stories" },
    ],
    []
  );

  useEffect(() => {
    if (connected && messages.length === 0) {
      addMessage({
        text: "안녕하세요. 무엇을 도와드릴까요?",
        isUser: false,
        timestamp: new Date().getTime(),
      });
      addComponent(() => (
        <ButtonCollections data={buttonsData} onSend={handleButton} />
      ));
    }
  }, [
    connected,
    messages.length,
    addMessage,
    addComponent,
    handleButton,
    buttonsData,
  ]);

  const handleExecute = () => {
    setShowModal(true);
  };

  const handleModalSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal(false);
      alert(`API 생성 완료!`);
      navigate(`/project/${project_id}`);
    }, 2000);
  };

  const handleAddClick = () => {
    setIsChatOpen(true);
  };

  return (
    <MainContent>
      <Breadcrumb
        onClick={() => navigate(`/project/${project_id}`)}
      >{`프로젝트 목록 > MICE MATE 플랫폼 > 채팅창 디자인 설정`}</Breadcrumb>
      <TitleContainer>
        <BackButton onClick={() => navigate(`/project/${project_id}`)}>
          {"<"}
        </BackButton>
        <Title>채팅창 디자인 설정</Title>
      </TitleContainer>
      <Description>생성하실 챗봇 AI의 특성을 직접 설정해주세요.</Description>

      <ContentWrapper>
        <FormsContainer>
          <ProjectForm>
            <FormField>
              <Label0>챗창 배경 스타일</Label0>
              <FlexContainer3>
                <FormField>
                  <Label>채팅 배경 색상</Label>
                  <ColorInput
                    type="color"
                    value={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.target.value)}
                  />
                  <ColorCode>{backgroundColor}</ColorCode>
                </FormField>
                <FormField>
                  <Label>상단바 색상</Label>
                  <ColorInput
                    type="color"
                    value={barColor}
                    onChange={(e) => setBarColor(e.target.value)}
                  />
                  <ColorCode>{barColor}</ColorCode>
                </FormField>
                <FormField>
                  <Label>하단바 색상</Label>
                  <ColorInput
                    type="color"
                    value={downbarColor}
                    onChange={(e) => setdownBarColor(e.target.value)}
                  />
                  <ColorCode>{downbarColor}</ColorCode>
                </FormField>
                <FormField>
                  <Label>입력란 색상</Label>
                  <ColorInput
                    type="color"
                    value={inputbarColor}
                    onChange={(e) => setinputBarColor(e.target.value)}
                  />
                  <ColorCode>{downbarColor}</ColorCode>
                </FormField>
              </FlexContainer3>
            </FormField>
            <FormField>
              <Label0>챗창 배경 스타일</Label0>
              <FlexContainer3>
                <FormField>
                  <Label>배경 패딩</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 1rem"
                    value={backgroundPadding}
                    onChange={(e) => setBackgroundPadding(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <Label>챗창 모서리</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 10px"
                    value={barBorder}
                    onChange={(e) => setBarBorder(e.target.value)}
                  />
                </FormField>
              </FlexContainer3>
            </FormField>
            <FormField>
              <Label0>챗창 위치</Label0>
              <FlexContainer3>
                <FormField>
                  <Label>높이</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 800px"
                    value={barHeight}
                    onChange={(e) => setBarHeight(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <Label>너비</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 500px"
                    value={barWidth}
                    onChange={(e) => setBarWidth(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <Label>좌우 간격</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 10px"
                    value={barLeft}
                    onChange={(e) => setBarLeft(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <Label>상하 간격</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 30px"
                    value={barTop}
                    onChange={(e) => setBarTop(e.target.value)}
                  />
                </FormField>
              </FlexContainer3>
            </FormField>
            <FormField>
              <Label2>챗창 배경 이미지</Label2>
              <InputField
                type="text"
                placeholder="Image URL"
                value={backgroundImage}
                onChange={(e) => setBackgroundImage(e.target.value)}
              />
            </FormField>
          </ProjectForm>

          <ProjectForm>
            <FormField>
              <Label0>사용자 버블 스타일</Label0>
              <FlexContainer3>
                <FormField>
                  <Label>버블 색상</Label>
                  <ColorInput
                    type="color"
                    value={userbubbleColor}
                    onChange={(e) => setUserbubbleColor(e.target.value)}
                  />
                  <ColorCode>{userbubbleColor}</ColorCode>
                </FormField>
                <FormField>
                  <Label>폰트 색상</Label>
                  <ColorInput
                    type="color"
                    value={userfontColor}
                    onChange={(e) => setuserFontColor(e.target.value)}
                  />
                  <ColorCode>{userfontColor}</ColorCode>
                </FormField>
                <FormField>
                  <Label>버블 모서리</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 3px"
                    value={userborderRadius}
                    onChange={(e) => setUserborderRadius(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <Label>버블 최대 너비</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 70%"
                    value={usermaxWidth}
                    onChange={(e) => setUsermaxWidth(e.target.value)}
                  />
                </FormField>
              </FlexContainer3>
            </FormField>
            <FormField>
              <Label2>AI 버블 스타일</Label2>
              <FlexContainer3>
                <FormField>
                  <Label>버블 색상</Label>
                  <ColorInput
                    type="color"
                    value={aibubbleColor}
                    onChange={(e) => setAibubbleColor(e.target.value)}
                  />
                  <ColorCode>{aibubbleColor}</ColorCode>
                </FormField>
                <FormField>
                  <Label>폰트 색상</Label>
                  <ColorInput
                    type="color"
                    value={aifontColor}
                    onChange={(e) => setAiFontColor(e.target.value)}
                  />
                  <ColorCode>{aifontColor}</ColorCode>
                </FormField>
                <FormField>
                  <Label>버블 모서리</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 3px"
                    value={aiborderRadius}
                    onChange={(e) => setAiborderRadius(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <Label>버블 최대 너비</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 70%"
                    value={aimaxWidth}
                    onChange={(e) => setAimaxWidth(e.target.value)}
                  />
                </FormField>
              </FlexContainer3>
            </FormField>
          </ProjectForm>

          <ProjectForm>
            <FormField>
              <Label0>AI 프로필 설정</Label0>
              <FlexContainer3>
                <FormField>
                  <Label>프로필 : 버블 위치 비율</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 1fr 9fr"
                    value={aiGrid}
                    onChange={(e) => setAiGrid(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <Label>프로필 사진</Label>
                  <InputField
                    type="text"
                    placeholder="Image URL"
                    value={aiProfile}
                    onChange={(e) => setAiProfile(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <Label>프로필 색상</Label>
                  <ColorInput
                    type="color"
                    value={aiprofileColor}
                    onChange={(e) => setAiprofileColor(e.target.value)}
                  />
                  <ColorCode>{aifontColor}</ColorCode>
                </FormField>
              </FlexContainer3>
            </FormField>
          </ProjectForm>

          <ProjectForm>
            <FormField>
              <Label0>스크롤 다운 버튼 스타일</Label0>
              <FlexContainer3>
                <FormField>
                  <Label>버튼 배경 색상</Label>
                  <ColorInput
                    type="color"
                    value={buttonColor}
                    onChange={(e) => setButtonColor(e.target.value)}
                  />
                  <ColorCode>{buttonColor}</ColorCode>
                </FormField>
                <FormField>
                  <Label>버튼 텍스트 색상</Label>
                  <ColorInput
                    type="color"
                    value={buttonTextColor}
                    onChange={(e) => setButtonTextColor(e.target.value)}
                  />
                  <ColorCode>{buttonTextColor}</ColorCode>
                </FormField>
                <FormField>
                  <Label>버튼 높이</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 2rem"
                    value={buttonHeight}
                    onChange={(e) => setButtonHeight(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <Label>버튼 너비</Label>
                  <InputField2
                    type="text"
                    placeholder="e.g., 2rem"
                    value={buttonWidth}
                    onChange={(e) => setButtonWidth(e.target.value)}
                  />
                </FormField>
              </FlexContainer3>
            </FormField>
          </ProjectForm>

          <ButtonContainer>
            <SaveButton2 onClick={handleAddClick}>미리보기</SaveButton2>
            <SaveButton2 onClick={handleExecute}>적용하기</SaveButton2>
          </ButtonContainer>
        </FormsContainer>

        {isChatOpen && (
          <ChatContainer>
            <ChatBotContainer
              config={{
                width: barWidth || "500px",
                height: barHeight || "800px",
                top: barTop || "30px",
                left: barLeft || "10px",
                borderRadius: "30px",
              }}
            >
              <Header
                config={{
                  height: "60px",
                  backgroundColor: barColor,
                }}
              />

              <MessagesContainer
                ref={messagesContainerRef}
                messages={messages}
                config={{
                  container: {
                    backgroundColor: backgroundColor, // 기본 배경색 (필요시 변경)
                    padding: backgroundPadding || "1rem",
                    backgroundImage:
                      `https://cdn.pixabay.com/photo/2017/11/09/21/41/cat-2934720_1280.jpg` ||
                      `url(${backgroundImage})`, //안됨
                  },
                  bubble: {
                    user: {
                      backgroundColor: userbubbleColor,
                      color: userfontColor,
                      borderRadius: userborderRadius || "3px",
                      maxWidth: usermaxWidth || "70%",
                    },
                    ai: {
                      backgroundColor: aibubbleColor || "black",
                      color: aifontColor || "white",
                      borderRadius: aiborderRadius || "3px",
                      maxWidth: aimaxWidth || "70%",
                      gridTemplateColumns: aiGrid || "1fr 9fr",
                      profile: {
                        profileSrc:
                          `https://ogq-sticker-global-cdn-z01.afreecatv.com/sticker/1806379ebe10edd/16.png` ||
                          `url(${aiProfile})`,
                        backgroundColor: aiprofileColor || "transparent",
                      },
                    },
                  },
                  button: {
                    //안됨
                    backgroundColor: buttonColor,
                    color: buttonTextColor,
                    height: buttonHeight || "2rem",
                    width: buttonWidth || "2rem",
                  },
                }}
              />
              <InputContainer
                config={{
                  container: {
                    backgroundColor: downbarColor || "#262628",
                  },
                  input: {
                    backgroundColor: inputbarColor || "#262628",
                    placeholderConnected: "Message",
                  },
                  color: "red",
                }}
                onSend={handleSend}
                connected={connected}
              />
            </ChatBotContainer>
          </ChatContainer>
        )}
      </ContentWrapper>

      {error && <p style={{ color: "red" }}>{error}</p>}
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              해당 디자인 설정을 채팅창에 <br></br>적용하시겠습니까?{" "}
            </ModalHeader>
            <ModalButton onClick={handleModalSubmit}>확인</ModalButton>
            <ModalButton onClick={() => setShowModal(false)}>취소</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
      {loading && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>API 생성중입니다...</ModalHeader>
            <Loader />
          </ModalContent>
        </ModalOverlay>
      )}
    </MainContent>
  );
};

export default Chatset;
