import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MDEditor from "@uiw/react-md-editor";
import FileIcon from "./img/file.svg";
import { uploadTxtdata } from "../../api/DatasetApi.js";

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
`;

const Breadcrumb = styled.p`
  font-size: 14px;
  color: white;
  margin-bottom: 0.8rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
`;

const BackButton = styled.span`
  cursor: pointer;
  font-size: 40px;
  margin-right: 0.8rem;
`;

const Title = styled.h1`
  font-size: 35px;
`;

const Description = styled.p`
  margin: 0.8rem 0;
  font-size: 1.1rem;
`;

const SettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #222;
  border-radius: 20px;
  padding: 2rem;
  color: #fff;
  width: 62.5rem;
`;

const ButtonContainer2 = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const FormField = styled.div``;

const Label = styled.label`
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  display: flex;

  &::after {
    content: ${(props) => (props.required ? "'*'" : "''")};
    color: red;
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }
`;

const Subtext = styled.p`
  font-size: 14px;
  color: white;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #333;
  color: white;
  transition: border-color 0.3s;

  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;

const Guide = styled.div`
  width: ${(props) => props.width || "45rem"};
  display: flex;
  flex-direction: column;
  background-color: #555;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: ${(props) => props.mb || "20px"};
`;

const GuideText = styled.div`
  color: white;
  font-size: 14px;
  line-height: 1.5;
  flex: 1;
  ul {
    list-style-type: square;
    list-style-position: inside;
  }
  li {
  }
`;

const TxtArea = styled.textarea`
  width: ${(props) => (props.isFocused ? "80%" : "80%")};
  min-height: 10px;
  height: ${(props) => (props.isFocused ? "150px" : "50px")};
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: transparent;
  color: black;
  margin-left: 20px;
  transition: border-color 0.3s, box-shadow 0.3s, width 0.3s, height 0.3s;
  resize: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;

  &:focus {
    border-color: #6200ea;
    box-shadow: 0 0 5px rgba(98, 0, 234, 0.5);
    outline: none;
  }
`;

const TagInput = styled(Input)`
  width: auto;
`;

const UploadButton = styled.button`
  background-color: #7000ff;
  color: white;
  align-items: flex-end;
  margin-left: 200px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #5a0c9e;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const ImageIcon = styled.img`
  width: 6rem;
  height: 6rem;
  margin-right: 1.25rem;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const Tag = styled.div`
  background-color: #7000ff;
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const RemoveTag = styled.span`
  background-color: #5a0c9e;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
`;

const FileInput = styled.input`
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #222;
  color: white;
  transition: border-color 0.3s;

  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: 630px;
  width: 400px;
  justify-content: flex-start;
  margin-top: 30px;
`;

const WhiteTable = styled.table`
  width: 90%;
  font-size: 14px;
  border-collapse: collapse;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
  overflow: hidden;
`;

const WhiteTableHeader = styled.th`
  background-color: #ccc;
  color: #333;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
`;

const WhiteTableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${({ selected }) => (selected ? "#d3cce3" : "#e9e9e9")};
  }
  &:nth-child(odd) {
    background-color: ${({ selected }) =>
      selected ? "#d3cce3" : "transparent"};
  }
  cursor: pointer;
`;

const WhiteTableCell = styled.td`
  padding: 10px;
  color: #333;
  text-align: center;
  border-bottom: 1px solid #ddd;
  position: relative;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  accent-color: #5500cc;
  width: 20px;
  height: 20px;
`;

const Button = styled.button`
  background-color: #6200ea;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #3700b3;
  }
`;

const ModalTitle = styled.div`
  color: black;
  font-size: 20px;
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  margin-top: 20px;
`;

const RowManagementContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 670px;
`;

const ModalButton = styled.button`
  background-color: ${(props) => (props.primary ? "#5500CC" : "#ddd")};
  color: ${(props) => (props.primary ? "white" : "black")};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.primary ? "#5500aa" : "#ccc")};
  }
`;

const UploadSelect = styled.select`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
`;

const Addtxt = () => {
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [uploadOption, setUploadOption] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textContent, setTextContent] = useState("");
  const [focusedInput, setFocusedInput] = useState({});
  const [label, setLabel] = useState("");
  const [fileOrText, setFileOrText] = useState(null);

  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFileOrText(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    console.log("업로드 타입:", uploadOption);

    const formData = new FormData();
    formData.append("label", label);
    formData.append("tag", tags.join(","));

    if (uploadOption === "file") {
      formData.append("file", fileOrText); // PDF, HWP 파일 추가
    } else if (uploadOption === "qna") {
      formData.append("file", fileOrText); // 엑셀, 한셀 파일 추가
    } else if (uploadOption === "direct") {
      formData.append("text", fileOrText || textContent); // 텍스트 추가
    } else {
      throw new Error("유효하지 않은 업로드 타입입니다.");
    }

    try {
      const response = await uploadTxtdata(formData);

      if (response.message === "success") {
        navigate("/dataset/txt/");
        handleCloseModal();
      } else {
        console.error("텍스트 데이터 업로드 실패:", response);
      }
    } catch (error) {
      console.error("텍스트 데이터 업로드 중 오류 발생:", error);
    }
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyUp = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const trimmedInput = tagInput.trim();
      if (trimmedInput !== "" && !tags.includes(trimmedInput)) {
        const newTags = trimmedInput
          .split(/\s+/)
          .filter((tag) => tag.trim() !== "");
        setTags((prevTags) => [...prevTags, ...newTags]);
        setTagInput("");
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };

  const handleUploadOptionChange = (event) => {
    setUploadOption(event.target.value);
  };

  const handleAddClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFocus = (field) => {
    setFocusedInput((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusedInput((prev) => ({ ...prev, [field]: false }));
  };

  const [datasets, setDatasets] = useState([
    {
      question: "-",
      answer: "-",
    },
    {
      question: "-",
      answer: "-",
    },
    {
      question: "-",
      answer: "-",
    },
  ]);

  const handleDatasetChange = (index, field, value) => {
    const updatedDatasets = [...datasets];
    updatedDatasets[index][field] = value;
    setDatasets(updatedDatasets);
  };

  const handleAddRow = () => {
    setDatasets((prev) => [
      ...prev,
      { question: "", answer: "", selected: false }, // 선택 상태 추가
    ]);
  };

  const [selectedRows, setSelectedRows] = useState(new Map()); // 변경된 상태

  const handleRowClick = (name) => {
    setSelectedRows((prev) => {
      const newSelectedRows = new Map(prev);
      if (newSelectedRows.has(name)) {
        newSelectedRows.delete(name);
      } else {
        newSelectedRows.set(name, true);
      }
      return newSelectedRows;
    });
  };

  const handleCheckboxChange = (index) => {
    setDatasets((prev) => {
      const newDatasets = [...prev];
      newDatasets[index].selected = !newDatasets[index].selected; // 선택 상태 토글
      return newDatasets;
    });
  };

  const handleDeleteRows = () => {
    setDatasets((prev) =>
      prev.filter((dataset) => !selectedRows.has(dataset.question))
    );
    setSelectedRows(new Map());
  };

  return (
    <>
      <MainContent>
        <Breadcrumb
          onClick={() => navigate("/dataset/txt/")}
        >{`데이터셋함 > 텍스트 데이터셋 리스트 > 업로드하기`}</Breadcrumb>
        <TitleContainer>
          <BackButton onClick={() => navigate("/dataset/txt")}>
            {"<"}
          </BackButton>
          <Title>텍스트 데이터셋 업로드</Title>
        </TitleContainer>
        <Description>
          업로드할 텍스트 파일에 대한 정보를 입력해주세요.
        </Description>
        <SettingContainer>
          <ButtonContainer2>
            <UploadSelect
              value={uploadOption}
              onChange={handleUploadOptionChange}
            >
              <option value="">선택하기</option>
              <option value="file">대량 텍스트 파일 업로드</option>
              <option value="qna">질문/대답 엑셀 업로드</option>
              <option value="direct">직접 입력</option>
            </UploadSelect>
          </ButtonContainer2>
          <FormField>
            <Label required>제목</Label>
            <Subtext>업로드할 텍스트 파일에 대한 제목을 설정해주세요.</Subtext>
            <Input
              type="text"
              placeholder="제목을 입력하세요."
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              required
            />
          </FormField>
          {uploadOption === "file" && (
            <>
              <FormField>
                <Label>태그 설정</Label>
                <Guide>
                  <GuideText>
                    <ul>
                      <li>
                        태그의 설정은 인공지능이 당신의 지식체계를 개념적으로
                        이해하게 하기 위한 중요한 수단 입니다.
                      </li>
                      <li>태그는 해시태그처럼 여러개를 설정할 수 있습니다.</li>
                      <li>
                        학습 효율을 높이기 위해서는 질문 대답과 연관성이 높은
                        태그를 설정 하세요.
                      </li>
                      <p>
                        예시) 라벨 : 주문 방법, 사용 방법, 자주하는 질문,
                        고객관리
                      </p>
                    </ul>
                  </GuideText>
                </Guide>
                <TagInput
                  type="text"
                  value={tagInput}
                  onChange={handleTagInputChange}
                  onKeyUp={handleTagInputKeyUp}
                  placeholder="태그를 입력하세요."
                />
                <TagList>
                  {tags.map((tag, index) => (
                    <Tag key={index}>
                      {tag}
                      <RemoveTag onClick={() => handleRemoveTag(tag)}>
                        ×
                      </RemoveTag>
                    </Tag>
                  ))}
                </TagList>
              </FormField>
              <FormField>
                <Label>대량 텍스트 파일 업로드</Label>
                <Subtext>- 텍스트 파일을 업로드해주세요.</Subtext>
                <ImageWrapper>
                  <ImageIcon src={FileIcon} alt="대표 이미지" />
                  <Guide width="24rem">
                    <GuideText>
                      <p>텍스트 파일 등록 가이드</p>
                      <ul>
                        <li>
                          10MB 이하의 .md, .txt, .json, .html 형식만 지원합니다.
                        </li>
                        <li>이미지가 포함되어있는 경우 URL 형식만 인식 가능</li>
                      </ul>
                    </GuideText>
                  </Guide>
                </ImageWrapper>
                <FileInput type="file" onChange={handleFileChange} />
              </FormField>
            </>
          )}
          {uploadOption === "qna" && (
            <>
              <FormField>
                <Label>태그 설정</Label>
                <Guide>
                  <GuideText>
                    <ul>
                      <li>
                        태그의 설정은 인공지능이 당신의 지식체계를 개념적으로
                        이해하게 하기 위한 중요한 수단 입니다.
                      </li>
                      <li>태그는 해시태그처럼 여러개를 설정할 수 있습니다.</li>
                      <li>
                        학습 효율을 높이기 위해서는 질문 대답과 연관성이 높은
                        태그를 설정 하세요.
                      </li>
                      <p>
                        예시) 라벨 : 주문 방법, 사용 방법, 자주하는 질문,
                        고객관리
                      </p>
                    </ul>
                  </GuideText>
                </Guide>
                <TagInput
                  type="text"
                  value={tagInput}
                  onChange={handleTagInputChange}
                  onKeyDown={handleTagInputKeyUp}
                  placeholder="태그를 입력하세요."
                />
                <TagList>
                  {tags.map((tag, index) => (
                    <Tag key={index}>
                      {tag}
                      <RemoveTag onClick={() => handleRemoveTag(tag)}>
                        ×
                      </RemoveTag>
                    </Tag>
                  ))}
                </TagList>
              </FormField>
              <FormField>
                <Label>질문/대답 엑셀 파일 업로드</Label>
                <Subtext>
                  - 질문/대답 형식의 엑셀 파일을 업로드해주세요.
                </Subtext>
                <ImageWrapper>
                  <ImageIcon src={FileIcon} alt="대표 이미지" />
                  <Guide width="24rem">
                    <GuideText>
                      <p>텍스트 파일 등록 가이드</p>
                      <ul>
                        <li>· 10MB 이하의 excel, csv 파일</li>
                        <li>· 질문/대답 형식의 엑셀 파일만 가능</li>
                      </ul>
                    </GuideText>
                  </Guide>
                </ImageWrapper>
                <FileInput type="file" onChange={handleFileChange} />
              </FormField>
              <FormField>
                <Label>직접 입력</Label>
                <Subtext>
                  질문과 대답을 직접 입력할 수 있습니다. <br></br>-질문에 대한
                  대답이 정확할 수록 인공지능의 할루시네이션(Hallucination) 을
                  최소화 할 수 있습니다.
                </Subtext>
                {/* Buttons for adding and deleting rows */}
                <RowManagementContainer>
                  <Button onClick={handleAddRow}>행 추가하기</Button>
                  <Button onClick={handleDeleteRows}>행 삭제하기</Button>
                </RowManagementContainer>
                <WhiteTable>
                  <thead>
                    <tr>
                      <WhiteTableHeader></WhiteTableHeader>
                      <WhiteTableHeader>질문</WhiteTableHeader>
                      <WhiteTableHeader>대답</WhiteTableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {datasets.map((dataset, index) => (
                      <WhiteTableRow
                        key={index}
                        selected={selectedRows.has(dataset.question)}
                        onClick={() => handleRowClick(dataset.question)}
                      >
                        <WhiteTableCell>
                          <Checkbox
                            checked={selectedRows.has(dataset.question)}
                            onChange={() =>
                              handleCheckboxChange(dataset.question)
                            }
                          />
                        </WhiteTableCell>
                        <WhiteTableCell>
                          <TxtArea
                            placeholder="질문을 입력하세요."
                            value={textContent}
                            onFocus={() => handleFocus(`question_${index}`)} // 인덱스를 추가하여 독립적으로 관리
                            onBlur={() => handleBlur(`question_${index}`)}
                            onChange={(e) =>
                              handleDatasetChange(
                                index,
                                "question",
                                e.target.value
                              )
                            }
                            isFocused={focusedInput[`question_${index}`]}
                          />
                        </WhiteTableCell>
                        <WhiteTableCell>
                          <TxtArea
                            placeholder="답변을 입력하세요."
                            value={textContent}
                            onFocus={() => handleFocus(`answer_${index}`)} // 인덱스를 추가하여 독립적으로 관리
                            onBlur={() => handleBlur(`answer_${index}`)}
                            onChange={(e) =>
                              handleDatasetChange(
                                index,
                                "answer",
                                e.target.value
                              )
                            }
                            isFocused={focusedInput[`answer_${index}`]}
                          />
                        </WhiteTableCell>
                      </WhiteTableRow>
                    ))}
                  </tbody>
                </WhiteTable>
              </FormField>
            </>
          )}

          {uploadOption === "direct" && (
            <>
              <FormField>
                <Label>태그 설정</Label>
                <Guide>
                  <GuideText>
                    <ul>
                      <li>
                        · 태그의 설정은 인공지능이 당신의 지식체계를 개념적으로
                        이해하게 하기 위한 중요한 수단 입니다.
                      </li>
                      <li>
                        · 태그는 해시태그처럼 여러개를 설정할 수 있습니다.
                      </li>
                      <li>
                        · 학습 효율을 높이기 위해서는 질문 대답과 연관성이 높은
                        태그를 설정 하세요.
                      </li>
                      <p>
                        예시) 라벨 : 주문 방법, 사용 방법, 자주하는 질문,
                        고객관리
                      </p>
                    </ul>
                  </GuideText>
                </Guide>
                <TagInput
                  type="text"
                  value={tagInput}
                  onChange={handleTagInputChange}
                  onKeyDown={handleTagInputKeyUp}
                  placeholder="태그를 입력하세요."
                />
                <TagList>
                  {tags.map((tag, index) => (
                    <Tag key={index}>
                      {tag}
                      <RemoveTag onClick={() => handleRemoveTag(tag)}>
                        ×
                      </RemoveTag>
                    </Tag>
                  ))}
                </TagList>
              </FormField>
              <FormField>
                <Label>텍스트 입력</Label>
                <Subtext>상세 내용을 입력해주세요.</Subtext>
                <Guide width="25rem" mb="0">
                  <GuideText>
                    <p>상세 내용 작성 가이드</p>
                    <ul>
                      <li>1,000자 이상 등록해야 합니다.</li>
                      <li>
                        텍스트 수가 너무 많을수록 학습 속도가 느려질 수
                        있습니다.
                      </li>
                      <li>
                        챗봇에 들어갔으면 하는 내용을 자세히 작성해주세요.
                      </li>
                    </ul>
                  </GuideText>
                </Guide>
              </FormField>
              <FormField>
                <MDEditor
                  value={textContent}
                  onChange={setTextContent}
                  height={500}
                />
              </FormField>
            </>
          )}
          <ButtonContainer>
            <UploadButton onClick={handleAddClick}>저장하기</UploadButton>
          </ButtonContainer>
        </SettingContainer>
      </MainContent>

      {isModalOpen && (
        <ModalBackdrop>
          <ModalContainer>
            <ModalTitle>데이터셋을 업로드하시겠습니까?</ModalTitle>
            <ModalButtonContainer>
              <ModalButton primary onClick={handleUpload}>
                예
              </ModalButton>
              <ModalButton onClick={handleCloseModal}>아니오</ModalButton>
            </ModalButtonContainer>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </>
  );
};

export default Addtxt;
