import axios from "axios";

const apiInstance = axios.create({
  baseURL: "https://440d72ab-4d00-4429-bb18-6a4b727357dd.mock.pstmn.io", // baseURL 설정 확인
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to fetch API list
export const fetchdbData = async () => {
  try {
    const response = await apiInstance.get("cfml/dbdata/list");
    console.log("API Response Data:", response.data); // 응답 데이터 출력
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data?.message || "API 리스트를 가져오는 데 실패했습니다."
      );
    } else {
      throw new Error("서버에 연결할 수 없습니다.");
    }
  }
};

// Fetch detailed data from the database table
export const fetchdbtableData = async () => {
  try {
    const response = await apiInstance.get("cfml/dbdata/table"); // Adjust the endpoint if necessary
    console.log("API Response Data for Table:", response.data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Fetch merged detailed data from the database
export const fetchdbmergeData = async () => {
  try {
    const response = await apiInstance.get("cfml/dbdata/merge"); // Adjust the endpoint if necessary
    console.log("API Response Data for Merge:", response.data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to fetch API list
export const fetchcfmlApiData = async () => {
  try {
    const response = await apiInstance.get("cfml/apidata/list");
    console.log("API Response Data:", response.data); // 응답 데이터 출력
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data?.message || "API 리스트를 가져오는 데 실패했습니다."
      );
    } else {
      throw new Error("서버에 연결할 수 없습니다.");
    }
  }
};

// Error handling utility function
const handleError = (error) => {
  if (error.response) {
    throw new Error(error.response.data?.message || "서버 에러");
  } else {
    throw new Error("서버에 연결할 수 없습니다.");
  }
};
