import { apiInstance, localInstance } from "./Axios.js";

//이미지 데이터를 업로드하는 함수
export const uploadImgdata = async (projectData) => {
  try {
    const response = await apiInstance.post(
      "/chat/imagedata/input",
      projectData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data?.message || "이미지 데이터 업로드에 실패했습니다."
      );
    } else {
      throw new Error("서버에 연결할 수 없습니다.");
    }
  }
};

export const uploadTxtdata = async (projectData) => {
  try {
    const response = await apiInstance.post(
      "/chat/txtdata/input",
      projectData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data?.message || "텍스트 데이터 업로드에 실패했습니다."
      );
    } else {
      throw new Error("서버에 연결할 수 없습니다.");
    }
  }
};

// 텍스트 데이터를 조회하는 함수
export const fetchTxtData = async (projectId) => {
  try {
    const response = await apiInstance.post("chat/txtdata/list", {
      params: { id: projectId }, // projectId를 params로 전송
    });

    return response.data.data; // Adjust according to the actual response structure
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data?.message ||
          "텍스트 데이터를 가져오는데 실패했습니다."
      );
    } else {
      throw new Error("서버에 연결할 수 없습니다.");
    }
  }
};

//이미지 데이터를 삭제하는 함수
export const deleteImageData = async (ImgDataId, userEmail, projectId) => {
  const response = await localInstance.post("delete_img_data/", {
    img_data_id: ImgDataId,
    user_email: userEmail,
    project_id: projectId,
  });
  return response.data;
};

// 이미지 데이터를 조회하는 함수
export const fetchImageData = async () => {
  try {
    const response = await apiInstance.get("chat/imagedata/list");
    return response.data.data; // Adjust according to the actual response structure
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data?.message ||
          "이미지 데이터를 가져오는데 실패했습니다."
      );
    } else {
      throw new Error("서버에 연결할 수 없습니다.");
    }
  }
};

// 이미지 리스트를 조회하는 함수
export const getImageData = async (user_email) => {
  const response = await localInstance.post("get_img_data_list/", {
    user_email: user_email,
  });
  return response.data;
};

// 텍스트 리스트를 조회하는 함수
export const getTextData = async (user_email, model_sort) => {
  const response = await localInstance.post("get_text_data_list/", {
    user_email: user_email,
    model_sort: 1,
  });
  return response.data;
};
