import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fetchuserData } from "../../api/MypageApi.js";
const MainContent = styled.main`
  flex: 1;
  padding: 30px;
  margin-bottom: 200px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin: 15px 0;
  color: #f1f1f1;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  color: #d1d1d1;
`;

const FormGroup = styled.div`
  margin: 40px 0;
`;

const FormGroup2 = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
  margin-right: 500px;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
  color: #e0e0e0;
`;

const Input = styled.input`
  width: 60%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #fff;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 12px 20px;
  font-size: 16px;
  color: white;
  background-color: #6a0dad;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #4e0a8e;
  }
`;

const Profile = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchuserData();
        console.log("Fetched user data:", data);
        setUserData(data[0]);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    loadUserData();
  }, []);

  if (!userData) {
    return <p>Loading...</p>;
  }

  const handleUpdate = () => {
    window.location.reload();
  };

  return (
    <MainContent>
      <Title>내 정보 수정</Title>
      <Description>나의 정보를 설정해주세요.</Description>

      <FormGroup>
        <Label>이메일(ID)</Label>
        <Input type="email" defaultValue={userData.email} />
      </FormGroup>

      <FormGroup>
        <Label>이름/기업명</Label>
        <div style={{ display: "flex", alignItems: "center", width: "30%" }}>
          <Input type="text" defaultValue={userData.name} style={{ flex: 1 }} />
          <Button style={{ marginLeft: "10px" }}>변경</Button>
        </div>
      </FormGroup>

      <FormGroup>
        <Label>비밀번호</Label>
        <Button>새 비밀번호 설정</Button>
      </FormGroup>

      <FormGroup>
        <Label>휴대전화번호</Label>
        <Input
          type="tel"
          defaultValue={userData.phone}
          placeholder="예) 010-1234-5678"
        />
      </FormGroup>

      <FormGroup>
        <Label>소속</Label>
        <Input type="text" defaultValue={userData.organ} />
      </FormGroup>

      <FormGroup>
        <Label>부서</Label>
        <Input type="text" defaultValue={userData.depart} />
      </FormGroup>

      <FormGroup>
        <Label>직함</Label>
        <Input type="text" defaultValue={userData.title} />
      </FormGroup>

      <FormGroup2>
        <Button onClick={handleUpdate}>수정하기</Button>{" "}
        {/* 수정하기 버튼 추가 */}
      </FormGroup2>
    </MainContent>
  );
};

export default Profile;
