import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Container = styled.div`
  background-color: ${({ props }) => props?.bc || "#fff"};
  border-radius: 10px;
  padding: ${({ props }) => props?.padding || "2rem"};
  width: ${({ props }) => props?.width || "70%"};
  display: ${({ props }) => props?.display || "block"};
  flex-direction: ${({ props }) => props?.fd || "column"};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
`;

const CustomModal = ({ children, onConfirm, onClose, props }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <Container props={props}>{children}</Container>
    </ModalOverlay>
  );
};

export default CustomModal;
