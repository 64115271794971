import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { fetchdbData, fetchcfmlApiData } from "../../api";

// Styled Components
const MainContent = styled.main`
  flex: 1;
  padding: 20px;
  color: white;
`;

const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 60px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: 10px;
  width: 300px;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const ExecuteButton = styled.button`
  background-color: #5500cc; /* Purple background */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: #5500aa;
  }
`;

const WhiteTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-left: 10px;
  margin-top: 20px;
  background-color: #f4f4f4; /* Light gray background for the table */
  border-radius: 8px;
  overflow: hidden;
`;

const WhiteTableHeader = styled.th`
  background-color: #ccc; /* Light gray background for header */
  color: #333;
  padding: 12px;
  border-bottom: 2px solid #ddd;
  font-weight: bold;
`;

const WhiteTableRow = styled.tr`
  cursor: pointer;
  &:nth-child(even) {
    background-color: ${({ selected }) =>
      selected ? "#d3cce3" : "#e9e9e9"}; /* Selected row background color */
  }
  &:nth-child(odd) {
    background-color: ${({ selected }) =>
      selected ? "#d3cce3" : "transparent"}; /* Selected row background color */
  }
`;

const WhiteTableCell = styled.td`
  padding: 12px;
  color: ${({ status }) => {
    if (status === "학습 완료") return "blue;"; // 형광 노란색
    if (status === "학습 진행중") return "orange"; // 회색
    if (status === "학습 전") return "red"; // 회색
    return "#333"; // 기본 색상
  }};
  text-align: center;
  border-bottom: 1px solid #ddd;
  position: relative;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  accent-color: #5500cc; /* Purple checkmark */
  width: 20px;
  height: 20px;
`;

// Modal Styles
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
`;

const ModalHeader = styled.div`
  margin-bottom: 30px;
`;

const ModalButton = styled.button`
  background-color: #5500cc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background-color: #5500aa;
  }
`;

const spin = keyframes`  
  0% { transform: rotate(0deg); }  
  100% { transform: rotate(360deg); }  
`;

const Loader = styled.div`
  margin-left: 140px;
  border: 8px solid #555;
  border-top: 8px solid #7000ff; /* 로딩 색상 */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite; /* 애니메이션 설정 */
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Separator = styled.hr`
  margin: 40px 0;
  border: 1px solid #555;
`;

const ApiListTitle = styled.h2`
  font-size: 28px;
  margin-left: 10px;
`;

const ButtonContainer2 = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const DeleteButton = styled.button`
  font-size: 16px;
  background-color: orange;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: gray;
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
`;

const ModalTitle = styled.div`
  color: black;
  font-size: 20px;
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  margin-top: 20px;
`;

const Learning = () => {
  const [action, setAction] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDatasets, setSelectedDatasets] = useState(new Map());
  const [selectedApis, setSelectedApis] = useState(new Map());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [apiList, setApiList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchdbData(); // Fetch datasets
        setDatasets(data); // Update datasets state

        const apiData = await fetchcfmlApiData(); // Fetch API data
        setApiList(apiData); // Update API list state
      } catch (error) {
        console.error(error.message);
      }
    };

    loadData(); // Call the data loading function
  }, []);

  const handleDatasetRowClick = (name) => {
    setSelectedDatasets((prev) => {
      const newSelectedRows = new Map(prev);
      if (newSelectedRows.has(name)) {
        newSelectedRows.delete(name);
      } else {
        newSelectedRows.set(name, true);
      }
      return newSelectedRows;
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteConfirmationClick = () => {
    setShowConfirmationModal(false); // Close any existing confirmation modal
    setIsModalOpen(true); // Open the confirmation modal for deletion
  };

  const handleDeleteSelected = () => {
    // Remove selected APIs from apiList
    setApiList((prevApiList) =>
      prevApiList.filter((api) => !selectedApis.has(api.datasetName))
    );
    setSelectedApis(new Map()); // Clear selected APIs after deletion
    setShowConfirmationModal(false); // Close the confirmation modal
    setIsModalOpen(false); // Close the deletion confirmation modal
  };

  const handleDeleteConfirmed = () => {
    // Remove selected datasets
    setDatasets((prevDatasets) =>
      prevDatasets.filter((dataset) => !selectedDatasets.has(dataset.name))
    );
    setSelectedDatasets(new Map()); // Clear selected datasets
    setIsModalOpen(false); // Close the confirmation modal after deletion
    setShowConfirmationModal(false); // Close the confirmation modal
  };

  const handleExecute = () => {
    if (action === "데이터셋 제거") {
      setShowConfirmationModal(true);
    } else if (action === "머신러닝 학습") {
      setShowModal(true);
    } else {
      alert("실행되었습니다!");
    }
  };

  const handleModalSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal(false);
      alert(`API 생성 완료!`);
    }, 2000);
  };

  const handleApiRowClick = (datasetName) => {
    setSelectedApis((prev) => {
      const newSelectedRows = new Map(prev);
      if (newSelectedRows.has(datasetName)) {
        newSelectedRows.delete(datasetName);
      } else {
        newSelectedRows.set(datasetName, true);
      }
      return newSelectedRows;
    });
  };

  return (
    <MainContent>
      <div>
        <Title>나의 API 리스트</Title>
        <Description>
          나의 데이터베이스 목록과 API 리스트를 확인하세요.
        </Description>
        <ButtonContainer>
          <select onChange={(e) => setAction(e.target.value)}>
            <option value="">선택하세요</option>
            <option value="머신러닝 학습">머신러닝 학습</option>
            <option value="데이터셋 제거">데이터셋 제거</option>
          </select>
          <ExecuteButton onClick={handleExecute}>실행</ExecuteButton>
        </ButtonContainer>
        <WhiteTable>
          <thead>
            <tr>
              <WhiteTableHeader></WhiteTableHeader>
              <WhiteTableHeader>데이터베이스 이름</WhiteTableHeader>
              <WhiteTableHeader>종류</WhiteTableHeader>
              <WhiteTableHeader>서버 주소</WhiteTableHeader>
              <WhiteTableHeader>포트</WhiteTableHeader>
              <WhiteTableHeader>계정</WhiteTableHeader>
              <WhiteTableHeader>데이터셋 크기 (Row X Column)</WhiteTableHeader>
              <WhiteTableHeader>업로드 된 날짜</WhiteTableHeader>
            </tr>
          </thead>
          <tbody>
            {datasets.map((dataset) => (
              <WhiteTableRow
                key={dataset.name}
                onClick={() => handleDatasetRowClick(dataset.name)}
                selected={selectedDatasets.has(dataset.name)}
              >
                <WhiteTableCell status={dataset.name}>
                  <Checkbox checked={selectedDatasets.has(dataset.name)} />
                </WhiteTableCell>
                <WhiteTableCell>{dataset.name}</WhiteTableCell>
                <WhiteTableCell>{dataset.type}</WhiteTableCell>
                <WhiteTableCell>{dataset.server}</WhiteTableCell>
                <WhiteTableCell>{dataset.port}</WhiteTableCell>
                <WhiteTableCell>{dataset.account}</WhiteTableCell>
                <WhiteTableCell>{dataset.size}</WhiteTableCell>
                <WhiteTableCell>{dataset.date}</WhiteTableCell>
              </WhiteTableRow>
            ))}
          </tbody>
        </WhiteTable>

        <Separator />
        <ApiListTitle>APIs</ApiListTitle>
        <ButtonContainer2>
          <DeleteButton onClick={handleDeleteConfirmationClick}>
            삭제하기
          </DeleteButton>
        </ButtonContainer2>
        <WhiteTable>
          <thead>
            <tr>
              <WhiteTableHeader></WhiteTableHeader>
              <WhiteTableHeader>데이터베이스 이름</WhiteTableHeader>
              <WhiteTableHeader>API 주소</WhiteTableHeader>
              <WhiteTableHeader>Input Params</WhiteTableHeader>
              <WhiteTableHeader>호출 방식</WhiteTableHeader>
              <WhiteTableHeader>학습 상태</WhiteTableHeader>
              <WhiteTableHeader>학습에 걸린 시간 (Seconds)</WhiteTableHeader>
              <WhiteTableHeader>현재까지 호출 횟수</WhiteTableHeader>
            </tr>
          </thead>
          <tbody>
            {apiList.map((api) => (
              <WhiteTableRow
                key={api.datasetName}
                onClick={() => handleApiRowClick(api.datasetName)}
                selected={selectedApis.has(api.datasetName)}
              >
                <WhiteTableCell>
                  <Checkbox checked={selectedApis.has(api.datasetName)} />
                </WhiteTableCell>
                <WhiteTableCell>{api.datasetName}</WhiteTableCell>
                <WhiteTableCell>{api.address}</WhiteTableCell>
                <WhiteTableCell>{api.inputParams}</WhiteTableCell>
                <WhiteTableCell>{api.method}</WhiteTableCell>
                <WhiteTableCell status={api.status}>
                  {api.status}
                </WhiteTableCell>
                <WhiteTableCell>{api.timeSpent}</WhiteTableCell>
                <WhiteTableCell>{api.currentUsage}</WhiteTableCell>
              </WhiteTableRow>
            ))}
          </tbody>
        </WhiteTable>

        {showConfirmationModal && (
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>선택한 데이터베이스를 삭제하시겠습니까?</ModalHeader>
              <ModalButton onClick={handleDeleteConfirmed}>확인</ModalButton>
              <ModalButton onClick={() => setShowConfirmationModal(false)}>
                취소
              </ModalButton>
            </ModalContent>
          </ModalOverlay>
        )}

        {showModal && (
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>
                선택하신 데이터베이스로 <br></br>API를 생성하시겠습니까?{" "}
              </ModalHeader>
              <ModalButton onClick={handleModalSubmit}>확인</ModalButton>
              <ModalButton onClick={() => setShowModal(false)}>
                취소
              </ModalButton>
            </ModalContent>
          </ModalOverlay>
        )}

        {loading && (
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>API 생성중입니다...</ModalHeader>
              <Loader />
            </ModalContent>
          </ModalOverlay>
        )}

        {isModalOpen && (
          <ModalBackdrop>
            <ModalContainer>
              <ModalTitle>해당 API를 삭제하시겠습니까?</ModalTitle>
              <ModalButtonContainer>
                <ModalButton primary onClick={handleDeleteSelected}>
                  예
                </ModalButton>
                <ModalButton onClick={handleCloseModal}>아니오</ModalButton>
              </ModalButtonContainer>
            </ModalContainer>
          </ModalBackdrop>
        )}
      </div>
    </MainContent>
  );
};

export default Learning;
