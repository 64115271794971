import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../../pages/chatbot/img/logo.svg";
import userIcon from "../../pages/chatbot/img/user.svg";

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #333;
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Logo = styled.img`
  width: 200px;
  height: 60px;
`;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;
  position: relative;
`;

const UserIcon = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 20px;
`;

const UserInfo = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 50px;
  right: -20px;
  width: 170px;
  color: #fff;
  background-color: #444;
  border: 1px solid #333;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.visible ? "block" : "none")};
  z-index: 1000;
`;

const DropdownItem = styled.div`
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: #333;
  }
`;

const Arrow = styled.span`
  margin-left: 1px;
  transition: transform 1s;
`;

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <Header>
      <LogoLink to="/main">
        <Logo src={logo} alt="Banya Logo" />
      </LogoLink>
      <UserSection>
        <UserIcon src={userIcon} alt="User Icon" />
        <UserInfo onClick={toggleDropdown} open={dropdownVisible}>
          John 님
        </UserInfo>
        <Arrow onClick={toggleDropdown} open={dropdownVisible}>
          {dropdownVisible ? "▲" : "▼"}
        </Arrow>
        {dropdownVisible && (
          <Dropdown visible={dropdownVisible}>
            <DropdownItem>john@gmail.com</DropdownItem>
            <DropdownItem onClick={() => navigate("/mypage")}>
              내 정보 수정
            </DropdownItem>
            <DropdownItem onClick={() => navigate("/mypage/charge/")}>
              빌링
            </DropdownItem>
            <DropdownItem onClick={() => navigate("/mypage/api/")}>
              API
            </DropdownItem>
            <DropdownItem onClick={() => navigate("/")}>로그아웃</DropdownItem>
          </Dropdown>
        )}
      </UserSection>
    </Header>
  );
};

export default Navbar;
