import axios from "axios";

export const apiInstance = axios.create({
  baseURL: "https://440d72ab-4d00-4429-bb18-6a4b727357dd.mock.pstmn.io", // baseURL 설정 확인
  headers: {
    "Content-Type": "application/json",
  },
});

export const localInstance = axios.create({
  baseURL: "http://localhost:8000/",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
});

export const formInstance = axios.create({
  baseURL: "http://localhost:8000/",
  headers: {
    "Content-Type": "multipart/form-data",
    withCredentials: true,
  },
});
