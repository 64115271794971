import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
`;

const ModalTitle = styled.div`
  color: black;
  font-size: 20px;
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  margin-top: 20px;
`;

const ModalButton = styled.button`
  background-color: ${(props) => (props.primary ? "#5133bb" : "#ddd")};
  color: ${(props) => (props.primary ? "white" : "black")};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.primary ? "#5133bb" : "#ccc")};
  }
`;

const Modal = ({ title, onConfirm, onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContainer>
        <ModalTitle>{title}</ModalTitle>
        <ModalButtonContainer>
          <ModalButton primary onClick={onConfirm}>
            예
          </ModalButton>
          <ModalButton onClick={onClose}>아니오</ModalButton>
        </ModalButtonContainer>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
