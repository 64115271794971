import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
`;

const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
`;

const ProjectForm = styled.div`
  background-color: #111;
  border-radius: 20px;
  padding: 20px;
  color: white;
  width: 1300px;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const FormField = styled.div`
  margin-bottom: 30px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Label = styled.label`
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: ${(props) => (props.required ? "'*'" : "''")};
    color: red;
    font-size: 20px;
    margin-left: 8px;
  }
`;

const Label2 = styled.h1`
  font-size: 25px;
  display: block;
  margin-bottom: 30px;
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: ${(props) => (props.required ? "'*'" : "''")};
    color: red;
    font-size: 20px;
    margin-left: 8px;
  }
`;

const InputField = styled.input`
  width: 500px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #222;
  color: white;
  transition: border-color 0.3s;

  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;

const SelectField = styled.select`
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #222;
  color: white;
  transition: border-color 0.3s;

  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  margin-top: 10px;
`;

const Slider = styled.input`
  -webkit-appearance: none;
  height: 8px;
  background: #555;
  border-radius: 5px;
  outline: none;
  flex: 1;
  margin-right: 20px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: ${({ value }) => `rgb(${(value / 100) * 255}, 0, 0)`};
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${({ value }) => `rgb(${(value / 100) * 255}, 0, 0)`};
    border-radius: 50%;
    cursor: pointer;
  }
`;

const SliderLabel = styled.label`
  font-size: 18px;
`;

const SaveButton = styled.button`
  background-color: #7000ff;
  margin-left: 1200px;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #5500cc;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
`;

const ModalHeader = styled.div`
  margin-bottom: 30px;
`;

const ModalButton = styled.button`
  background-color: #5500cc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background-color: #5500aa;
  }
`;

const spin = keyframes`  
  0% { transform: rotate(0deg); }  
  100% { transform: rotate(360deg); }  
`;

const Loader = styled.div`
  margin-left: 140px;
  border: 8px solid #555;
  border-top: 8px solid #7000ff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const PROJECT_ID = 1;
const project_id = PROJECT_ID;

const Aiset = () => {
  const [creativityLevel, setCreativityLevel] = useState(50);
  const [imageUsageLevel, setImageUsageLevel] = useState(50);
  const [knowledgeLevel, setKnowledgeLevel] = useState(50);
  const [loading, setLoading] = useState(false);
  const [creationLevel, setCreationLevel] = useState(50);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleExecute = () => {
    setShowModal(true);
  };

  const handleModalSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal(false);
      alert(`API 생성 완료!`);
      navigate(`/project/${project_id}`);
    }, 2000);
  };

  const handleCreativitySliderChange = (event) => {
    setCreativityLevel(event.target.value);
  };

  const handleImageUsageSliderChange = (event) => {
    setImageUsageLevel(event.target.value);
  };

  const handleKnowledgeSliderChange = (event) => {
    setKnowledgeLevel(event.target.value);
  };

  const handleCreationSliderChange = (event) => {
    setCreationLevel(event.target.value);
  };

  return (
    <MainContent>
      <Title>AI 특성 설정</Title>
      <Description>생성하실 챗봇 AI의 특성을 직접 설정해주세요.</Description>
      <ProjectForm>
        <Label2>채팅 AI 설정</Label2>
        <FormField>
          <Label required>이름</Label>
          <InputField type="text" placeholder="프로젝트1" />
        </FormField>

        <FlexContainer>
          <FormField>
            <Label required>언어 설정</Label>
            <SelectField>
              <option>한국어 (Korean)</option>
              <option>영어 (English)</option>
              <option>중국어 (Chinese)</option>
            </SelectField>
          </FormField>
        </FlexContainer>
        <FlexContainer>
          <FormField>
            <Label required>단어 수 제한</Label>
            <SelectField>
              <option>~100,000</option>
              <option>~50,000</option>
              <option>~10,000</option>
            </SelectField>
          </FormField>

          <FormField>
            <Label required>이미지 수 제한</Label>
            <SelectField>
              <option>~50</option>
              <option>~100</option>
              <option>~200</option>
            </SelectField>
          </FormField>
        </FlexContainer>

        <FormField>
          <Label required>자체 발화 강도</Label>
          <FlexContainer>
            <SliderContainer>
              <Slider
                type="range"
                min="0"
                max="100"
                value={creativityLevel}
                onChange={handleCreativitySliderChange}
                style={{
                  background: `linear-gradient(to right, red ${
                    (creativityLevel / 100) * 100
                  }%, #555 ${(creativityLevel / 100) * 100}%)`,
                }}
              />
              <SliderLabel>{creativityLevel}</SliderLabel>
            </SliderContainer>
          </FlexContainer>
        </FormField>
        <FormField>
          <Label required>이미지 사용 강도</Label>
          <FlexContainer>
            <SliderContainer>
              <Slider
                type="range"
                min="0"
                max="100"
                value={imageUsageLevel}
                onChange={handleImageUsageSliderChange}
                style={{
                  background: `linear-gradient(to right, red ${
                    (imageUsageLevel / 100) * 100
                  }%, #555 ${(imageUsageLevel / 100) * 100}%)`,
                }}
              />
              <SliderLabel>{imageUsageLevel}</SliderLabel>
            </SliderContainer>
          </FlexContainer>
        </FormField>
        <FormField>
          <Label required>표준 지식 강도</Label>
          <FlexContainer>
            <SliderContainer>
              <Slider
                type="range"
                min="0"
                max="100"
                value={knowledgeLevel}
                onChange={handleKnowledgeSliderChange}
                style={{
                  background: `linear-gradient(to right, red ${
                    (knowledgeLevel / 100) * 100
                  }%, #555 ${(knowledgeLevel / 100) * 100}%)`,
                }}
              />
              <SliderLabel>{knowledgeLevel}</SliderLabel>
            </SliderContainer>
          </FlexContainer>
        </FormField>
        <FormField>
          <Label required>창작 강도</Label>
          <FlexContainer>
            <SliderContainer>
              <Slider
                type="range"
                min="0"
                max="100"
                value={creationLevel}
                onChange={handleCreationSliderChange}
                style={{
                  background: `linear-gradient(to right, red ${
                    (creationLevel / 100) * 100
                  }%, #555 ${(creationLevel / 100) * 100}%)`,
                }}
              />
              <SliderLabel>{creationLevel}</SliderLabel>
            </SliderContainer>
          </FlexContainer>
        </FormField>
      </ProjectForm>
      <SaveButton onClick={handleExecute}>챗봇 생성하기</SaveButton>

      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>
              해당 설정을 바탕으로 한 <br></br>채팅 AI를 생성하시겠습니까?{" "}
            </ModalHeader>
            <ModalButton onClick={handleModalSubmit}>확인</ModalButton>
            <ModalButton onClick={() => setShowModal(false)}>취소</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
      {loading && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>API 생성중입니다...</ModalHeader>
            <Loader />
          </ModalContent>
        </ModalOverlay>
      )}
    </MainContent>
  );
};

export default Aiset;
