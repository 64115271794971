import React, { useState } from "react";
import styled from "styled-components";
import BanyaLogo from "../../assets/img/banya_trainer_logo_light.svg";
import { duplicateCheckAPI } from "../../api/AuthApi";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
`;

const Logo = styled.div`
  width: 100%;
  max-width: 18.75rem;
  margin-bottom: 1.25rem;
  text-align: center;

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 20rem;
`;

const Label = styled.label`
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0.3125rem;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  height: 2.5rem;
  padding: 0 0.625rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 0.3125rem;
`;

const CheckButton = styled.button`
  height: 2.5rem;
  min-width: 5rem;
  margin-left: 0.625rem;
  font-size: 0.9rem;
  color: #333;
  background-color: #f0f0f0;
  border: 1px solid #dcdcdc;
  border-radius: 0.3rem;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const SubmitButton = styled.button`
  height: 2.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;

const SignUp = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleDuplicateCheck = () => {
    duplicateCheckAPI(email)
      .then((res) => {
        if (res.status === 200) {
          alert(`사용가능한 이메일입니다. ${email}`);
        } else {
          alert("이미 가입된 이메일입니다.");
        }
      })
      .catch((err) => console.log(err));
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{6,20}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username.length > 16) {
      alert("아이디는 최대 16자리로 제한됩니다.");
      return;
    }

    if (!username || !email || !password || !confirmPassword) {
      alert("모든 필드를 입력하세요.");
      return;
    }
    if (!validatePassword(password)) {
      alert(
        "비밀번호는 영문 대문자, 특수문자가 하나 이상 포함되고, 영문 소문자나 숫자로만 구성되며, 6자에서 20자 길이여야 합니다."
      );
      return;
    }
    if (password !== confirmPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    alert("가입이 완료되었습니다!");
  };

  return (
    <Container>
      <Logo>
        <img src={BanyaLogo} alt="Cloud Trainer Banya" />
      </Logo>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="username">아이디*</Label>
        <Input
          type="text"
          username="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          maxLength={20}
        />

        <Label htmlFor="email">이메일*</Label>
        <InputWrapper>
          <Input
            type="email"
            username="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@banya.ai"
          />
          <CheckButton type="button" onClick={handleDuplicateCheck}>
            중복체크
          </CheckButton>
        </InputWrapper>

        <Label htmlFor="password">비밀번호*</Label>
        <Input
          type="password"
          username="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Label htmlFor="confirmPassword">비밀번호 확인*</Label>
        <Input
          type="password"
          username="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <SubmitButton type="submit">가입</SubmitButton>
      </Form>
    </Container>
  );
};

export default SignUp;
