import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
  color: white;
`;

const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 30px;
`;

const Text = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle;
`;

const InputContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  width: 600px;
`;

const InputContainer2 = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 20px;
  width: auto;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 15px;
  margin-right: 10px;
  background-color: #fff;
  color: #222;
`;

const Button = styled.button`
  background-color: #ffc700;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    color: white;
    background-color: #c39904;
  }
`;

const Button2 = styled.button`
  background-color: #444;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-left: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    color: white;
    background-color: black;
  }
`;

const DatabaseSelectContainer = styled.div`
  margin-left: 10px;
  margin-bottom: 30px;
  width: 250px; /* Make the select container narrower */
`;

const SelectLabel = styled.label`
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
  color: white;
`;

const DatabaseSelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #333;
  background-color: #222;
  color: white;
  border-radius: 5px;
  appearance: none;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="white" d="M0 0l2 2 2-2z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;

  &:hover {
    border-color: #555;
  }

  option {
    background-color: #222;
    color: white;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #333;
  color: white;
  padding: 10px;
  border-right: 1px solid #555;

  &:last-child {
    border-right: none;
  }
`;

const TableRow = styled.tr`
  background-color: #222;
  &:nth-child(even) {
    background-color: #333;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  color: white;
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #555;

  &:last-child {
    border-right: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const SaveButton = styled.button`
  background-color: #5500cc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5500aa;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const BackButton = styled.span`
  cursor: pointer;
  margin-top: 10px;
  font-size: 40px;
  margin-right: 10px;
`;

const Breadcrumb = styled.p`
  font-size: 14px;
  color: white;
  margin: 0 0 10px 10px;
`;

const ProjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #222;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 60px;
`;

const ProjectDetail = styled.p`
  margin: 10px 0;
  margin-left: 10px;
  line-height: 1.8;
`;

const Heading = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
`;

const Database = () => {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);

  const handleConnect = () => {
    setIsConnected(true);
  };

  const handleDisconnect = () => {
    setIsConnected(false);
  };

  return (
    <MainContent>
      <Breadcrumb onClick={() => navigate("/database")}>
        {"데이터베이스 > 데이터베이스 추가하기"}
      </Breadcrumb>
      <TitleContainer>
        <BackButton onClick={() => navigate("/database")}>{"<"}</BackButton>
        <Title>데이터베이스 추가</Title>
      </TitleContainer>
      <Description>추가할 데이터베이스를 연결해주세요.</Description>
      <ProjectInfoContainer>
        <ProjectDetail>
          <Heading> 데이터베이스 추가하는 방법?</Heading>
          <br />
          - 데이터베이스와 실시간 연동을 위한 사용자 시스템의 데이터베이스를
          설정하고 연결합니다.
          <br />- 텍스트 또는 이미지 데이터셋 리스트에서 선택한 데이터셋이 특정
          프로젝트에 할당되도록 합니다.
        </ProjectDetail>
      </ProjectInfoContainer>
      <div>
        <Text>데이터셋 이름:</Text>
        <InputContainer2>
          <Input type="text" placeholder="데이터셋 1" />
        </InputContainer2>
      </div>{" "}
      <br />
      {/* Database Select Section */}
      <DatabaseSelectContainer>
        <SelectLabel>데이터베이스 선택</SelectLabel>
        <DatabaseSelect>
          <option value="postgresql">PostGreSQL</option>
          <option value="mysql">MySQL</option>
          <option value="mssql">MSSQL</option>
        </DatabaseSelect>
      </DatabaseSelectContainer>
      <div>
        <Text>서버 주소:</Text>
        <InputContainer>
          <Input type="text" placeholder="192.168.0.71" />
        </InputContainer>
        <Text> 포트:</Text>
        <InputContainer2>
          <Input type="text" placeholder="8000" />
          <Button onClick={handleConnect}>연결하기</Button>
          <Button2 onClick={handleDisconnect}>연결끊기</Button2>
        </InputContainer2>
      </div>{" "}
      <br />
      <div>
        <Text>계정:</Text>
        <InputContainer2>
          <Input type="text" placeholder="kimyejin" />
        </InputContainer2>
        <Text> 비밀번호:</Text>
        <InputContainer2>
          <Input type="text" placeholder="021211" />
        </InputContainer2>
      </div>
      {/* Conditionally render the table based on connection status */}
      {isConnected && (
        <Table>
          <thead>
            <tr>
              <TableHeader>User_ID</TableHeader>
              <TableHeader>Item_ID</TableHeader>
              <TableHeader>Review_Points</TableHeader>
            </tr>
          </thead>
          <tbody>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>1</TableCell>
              <TableCell>2.5</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2</TableCell>
              <TableCell>2</TableCell>
              <TableCell>5</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>5</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>4</TableCell>
              <TableCell>1.8</TableCell>
            </TableRow>
          </tbody>
        </Table>
      )}
      <ButtonContainer>
        <SaveButton onClick={() => navigate("/database")}>저장하기</SaveButton>
      </ButtonContainer>
    </MainContent>
  );
};

export default Database;
