import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  // getArtifactSort,
  getProjectDetail,
  getTrainedProjectDetail,
} from "../../api/ProjectApi.js";
import ProjectDetailApiList from "./ProjectDetailApiList.js";
import CustomModal from "../../components/CustomModal.js";
import closeLogo from "../../assets/img/general_close.svg";

const Breadcrumb = styled.p`
  font-size: 1rem;
  color: white;
  margin-bottom: 0.5rem;
`;

const BackButton = styled.span`
  cursor: pointer;
  font-size: 2.5rem;
  margin-right: 1rem;
`;

const Title = styled.h1`
  font-size: 2rem;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  background-color: #222;
  border-radius: 1.25rem;
  padding: 1rem;
`;

const ImageWrapper = styled.div`
  border-radius: 1rem;
  overflow: hidden;
  width: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    border-radius: 1rem;
  }
`;

const Description = styled.div`
  margin-left: 2rem;

  p {
    margin: 0.5rem 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

const Separator = styled.hr`
  margin: 2.5rem 0;
  border: 0.5px solid #666;
`;

const TooltipButton = styled.button`
  all: unset;
  cursor: pointer;
  margin-top: 1rem;
`;

const Tooltip = styled.div`
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 50vh;

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  h1 {
    font-size: 1.5rem;
  }
`;

const USER_EMAIL = "first@daiblab.com";
const user_email = USER_EMAIL;

const ProjectDetail = () => {
  const navigate = useNavigate();

  // 기본 정보 state
  const project_id = useLocation().pathname.split("/").pop();

  // const [artifactSort, setArtifactSort] = useState([]);
  // const [artifactNames, setArtifactNames] = useState("");
  const [projectInfo, setProjectInfo] = useState([]);
  const [trained, setTrained] = useState(false);

  // 모달 제어
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // 프로젝트 디테일 가져오기
    getTrainedProjectDetail(user_email, project_id)
      .then((res) => {
        if (res.length > 0) {
          setProjectInfo(res[0]);
          setTrained(true);
        } else {
          getProjectDetail(user_email, project_id).then((res) => {
            setProjectInfo(res);
          });
        }
      })
      .catch((err) => console.log(err));
  }, [project_id]);

  // useEffect(() => {
  //   const parseArtifactSelected = () => {
  //     if (
  //       !projectInfo ||
  //       !artifactSort.length ||
  //       !projectInfo.artifact_selected
  //     ) {
  //       return ""; // undefind 예외처리
  //     }

  //     return projectInfo?.artifact_selected
  //       .split(":")
  //       .map(
  //         (id) =>
  //           artifactSort.find((item) => item.id === parseInt(id))
  //             ?.artifact_sort_name
  //       )
  //       .filter(Boolean)
  //       .join(", ");
  //   };
  //   setArtifactNames(parseArtifactSelected());
  // }, [project_id, projectInfo, artifactSort]);

  return (
    <>
      <Breadcrumb
        onClick={() => navigate("/project")}
      >{`프로젝트 목록 >  ${projectInfo?.project_name}`}</Breadcrumb>
      <TitleContainer>
        <BackButton onClick={() => navigate("/project")}>{"<"}</BackButton>
        <Title>{projectInfo?.project_name}</Title>
      </TitleContainer>
      <DescriptionWrapper>
        <ImageWrapper>
          <img
            src={projectInfo?.project_thum_url}
            alt={projectInfo?.project_name}
          />
        </ImageWrapper>
        <Description>
          <p>분야 : {projectInfo?.project_sort_ko}</p>
          <p>학습 데이터 형식 : {projectInfo?.project_data_sort_ko}</p>
          <p>설명 : {projectInfo?.project_desc}</p>
        </Description>
      </DescriptionWrapper>
      <Separator />
      {/* 멀티 모달 API List */}
      {
        <ProjectDetailApiList
          props={{ project_id, projectInfo }}
          trained={trained}
        />
      }

      {/* API 사용 방법 툴팁 */}
      <TooltipButton onClick={() => setIsModalOpen(true)}>
        API 사용 방법 ℹ️
      </TooltipButton>
      {isModalOpen && (
        <CustomModal onClose={() => setIsModalOpen(false)}>
          <Tooltip>
            <div className="title-wrapper">
              <h1>API 사용 방법</h1>
              <img
                src={closeLogo}
                alt="close"
                onClick={() => setIsModalOpen(false)}
              />
            </div>
          </Tooltip>
        </CustomModal>
      )}
    </>
  );
};

export default ProjectDetail;
