import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    ns: ["landing"],
    defaultNS: "landing",

    fallbackLng: "ko",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        landing: require("./en/landing.json"),
      },
      ko: {
        landing: require("./ko/landing.json"),
      },
    },
  });

export default i18n;
