import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getImageModel, getLanguageModel } from "../../api/ProjectApi.js";
import Modal from "../../components/Modal.js";

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h2`
  font-size: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background-color: ${(props) => props.bc || "#5133BB"};
  color: #fff;
  margin-left: 1rem;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.hoverBc || "#392582"};
  }
`;

const GridContainer = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 0.1fr repeat(7, 1fr); /* 8열 테이블 */

  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: scroll;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media (max-width: 480px) {
    font-size: 0.5rem;
  }
`;

const GridHeader = styled.div`
  background-color: #333;
  color: white;
  padding: 0.75rem 0.5rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #ddd;
`;

const GridItem = styled.div`
  padding: 0.75rem 0.5rem;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #333;
  &:nth-child(8n) {
    border-right: none; /* 마지막 열 구분 */
  }
  &:nth-child(-n + 8) {
    background-color: #333; /* 헤더 색상 */
    color: white;
    font-weight: bold;
  }
`;

const EmptyApi = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin: 1rem 0;
  grid-column: 1 / -1; /* Span all columns */
  padding: 1.5rem;
`;

const CALL_METHODS = {
  1: "GET",
  2: "POST",
};

const SAVE_TYPES = {
  1: "FILE",
  2: "Vector DB",
};

const ProjectDetailApiList = ({ props, trained }) => {
  const { project_id, projectInfo } = props;

  // const [selectedApis, setSelectedApis] = useState(new Map());
  // const [apiData, setApiData] = useState([]);

  const [imageModel, setImageModel] = useState("");
  const [languageModel, setLanguageModel] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelect = (id) => {
    setSelectedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    ); // 선택된 리스트 [1, 2]
  };

  const handleDeleteSelected = () => {
    // const remainingItems = data.filter(
    //   (item) => !selectedItems.includes(item.id)
    // );
    setSelectedItems([]); // 초기화
    handleCloseModal();
  };
  // const handleApiRowClick = (datasetName) => {
  //   setSelectedApis((prev) => {
  //     const newSelectedRows = new Map(prev);
  //     if (newSelectedRows.has(datasetName)) {
  //       newSelectedRows.delete(datasetName);
  //     } else {
  //       newSelectedRows.set(datasetName, true);
  //     }
  //     return newSelectedRows;
  //   });
  // };

  useEffect(() => {
    if (projectInfo && projectInfo.img_model_id) {
      getImageModel(projectInfo.img_model_id)
        .then((res) => setImageModel(res.name))
        .then((err) => console.log(err));
    }
    if (projectInfo && projectInfo.lang_model_id) {
      getLanguageModel(projectInfo.lang_model_id)
        .then((res) => setLanguageModel(res.name))
        .then((err) => console.log(err));
    }
  }, [projectInfo]);

  return (
    <>
      <TitleWrapper>
        <Title>멀티 모달 API</Title>
        {trained && (
          <ButtonContainer>
            <Button onClick={() => navigate(`/project/${project_id}/chatset`)}>
              웹앱 디자인하기
            </Button>
            <Button onClick={() => navigate(`/project/${project_id}/testset`)}>
              성능 테스트하기
            </Button>
            <Button onClick={() => setIsModalOpen(true)}>삭제하기</Button>
          </ButtonContainer>
        )}
      </TitleWrapper>
      <GridContainer>
        <GridHeader></GridHeader>
        <GridHeader>프로젝트 이름</GridHeader>
        <GridHeader>생성일</GridHeader>
        <GridHeader>API 주소</GridHeader>
        <GridHeader>호출 방식</GridHeader>
        <GridHeader>저장 방식</GridHeader>
        <GridHeader>시각 모델</GridHeader>
        <GridHeader>언어 모델</GridHeader>
        {trained ? (
          <>
            <GridItem>
              <input
                type="checkbox"
                checked={selectedItems.includes(projectInfo?.id)}
                onChange={() => handleSelect(projectInfo?.id)}
              />
            </GridItem>
            <GridItem>{projectInfo?.project_name}</GridItem>
            <GridItem>{projectInfo?.project_created_date}</GridItem>
            <GridItem>{projectInfo?.api_url}</GridItem>
            <GridItem>{CALL_METHODS[projectInfo?.call_method]}</GridItem>
            <GridItem>{SAVE_TYPES[projectInfo?.save_type]}</GridItem>
            <GridItem>{imageModel}</GridItem>
            <GridItem>{languageModel}</GridItem>
          </>
        ) : (
          <EmptyApi>아직 프로젝트에 해당된 API가 없습니다.</EmptyApi>
        )}
      </GridContainer>

      {isModalOpen && (
        <Modal
          title={"해당 API를 삭제하시겠습니까?"}
          onConfirm={handleDeleteSelected}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default ProjectDetailApiList;
