import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getProjectList, deleteProject } from "../../api/ProjectApi.js";
import Modal from "../../components/Modal.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Title = styled.h1`
  font-size: 2.5rem;
`;

const Description = styled.p`
  font-size: 1.125rem;
`;

const Guide = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #222;
  border-radius: 20px;
  padding: 1.5rem 2rem;
  .title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #fff;
    padding-bottom: 1rem;
  }
  p {
    color: #fff;
    line-height: 1.5rem;
  }
`;

const CardListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
`;

const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  padding: 1rem;
  border-radius: 1.25rem;
  background-color: #333;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #444;
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;

const CheckboxWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  width: 1.2rem;
  height: 1.2rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 30%;
  outline: none;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  overflow: hidden;
  width: 13rem;
  height: 7rem;
  img {
    width: 100%;
    border-radius: 15px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  overflow: hidden;
  width: 13rem;

  h2 {
    font-size: 1.5rem;
    margin: 0.8rem 0 0.4rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    color: #aaa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Button = styled.button`
  background-color: ${(props) => props.bc || "#5133bb"};
  color: ${(props) => props.color || "#fff"};
  margin-left: 1rem;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.hoverBc || "#392582"};
  }
`;

const USER_EMAIL = "first@daiblab.com";

const Project = () => {
  const navigate = useNavigate();

  const [projectList, setProjectList] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);
  const [selected, setSelected] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClickProject = (projectId) => {
    if (!deleteMode) {
      navigate(`/project/${projectId}`);
    }
  };

  const handleDeleteModeToggle = () => {
    setDeleteMode((prev) => !prev);
    setSelected([]);
  };

  const toggleSelect = (projectId) => {
    setSelected((prev) =>
      prev.includes(projectId)
        ? prev.filter((id) => id !== projectId)
        : [...prev, projectId]
    );
  };

  const handleCheckboxClick = (e, projectId) => {
    e.stopPropagation();
    toggleSelect(projectId);
  };

  const handleCardClick = (projectId) => {
    if (deleteMode) {
      toggleSelect(projectId);
    } else {
      handleClickProject(projectId);
    }
  };

  const handleDeleteSelected = () => {
    // 삭제 API
    Promise.all(
      selected.map((projectId) => deleteProject(projectId)) // 각 선택한 프로젝트에 대해 삭제 API 호출
    ).then(() => {
      // 삭제 후 프로젝트 목록을 새로 고침
      getProjectList(USER_EMAIL)
        .then((res) => setProjectList(res))
        .catch((err) => console.log(err));
      setSelected([]);
      setDeleteMode(false);
      setDeleteModal(false);
    });
  };

  useEffect(() => {
    getProjectList(USER_EMAIL)
      .then((res) => setProjectList(res))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container>
      <Title>프로젝트</Title>
      <Description>
        데이터셋 생성을 위해 나의 프로젝트를 생성해주세요.
      </Description>
      <Guide>
        <p className="title">사용 방법</p>
        <p>
          - 최종 사용자용 API를 생성하기 위해서는 아래의 데이터셋함에서 텍스트,
          이미지 데이터를 업로드 후 학습시킬 데이터들을 선택하여 나의 프로젝트에
          할당합니다.
        </p>
        <p>
          - 텍스트 또는 이미지 데이터셋 리스트에서 선택한 데이터셋이 특정
          프로젝트에 할당되도록 합니다.
        </p>
      </Guide>
      <CardListWrapper>
        <ButtonWrapper>
          <Button onClick={() => navigate("/project/add")}>
            <span>프로젝트 생성</span>
          </Button>
          <Button onClick={handleDeleteModeToggle}>
            {deleteMode ? "취소" : "삭제하기"}
          </Button>
          {deleteMode && selected.length > 0 && (
            <Button
              bc="#4274ff"
              hoverBc="#2c499a"
              onClick={() => setDeleteModal(true)}
            >
              선택 삭제
            </Button>
          )}
        </ButtonWrapper>
        <CardList>
          {projectList?.map((project) => (
            <Card key={project.id} onClick={() => handleCardClick(project.id)}>
              {deleteMode && (
                <CheckboxWrapper>
                  <HiddenCheckbox
                    checked={selected.includes(project.id)}
                    onClick={(e) => handleCheckboxClick(e, project.id)}
                    onChange={() => {}} // React controlled component 경고 방지
                  />
                </CheckboxWrapper>
              )}
              <ImageWrapper>
                <img src={project.thum_image_url} alt={project.project_id} />
              </ImageWrapper>
              <ContentWrapper>
                <h2>{project.project_name}</h2>
                <p>{project.project_desc}</p>
              </ContentWrapper>
            </Card>
          ))}
        </CardList>
      </CardListWrapper>

      {deleteModal && (
        <Modal
          title={`선택한 프로젝트 ${selected} 를 삭제하시겠습니까?`}
          onConfirm={handleDeleteSelected}
          onClose={() => setDeleteModal(false)}
        />
      )}
    </Container>
  );
};

export default Project;
