import { apiInstance, localInstance, formInstance } from "./Axios.js";

export const getProjectList = async (user_email) => {
  const response = await localInstance.post("project_card_list/", {
    user_email: user_email,
  });
  return response.data;
};

// 프로젝트 사전 정보 조회
export const getProjectDetailReady = async () => {
  const response = await localInstance.get("project_detail_ready/");
  return response.data;
};

// 프로젝트 분야 조회
export const getProjectSort = async () => {
  const response = await localInstance.get("project_sort/");
  return response.data;
};

// 학습 데이터 형식조회
export const getProjectDataSort = async () => {
  const response = await localInstance.get("project_data_sort/");
  return response.data;
};

// 결과물 형식 조회
export const getArtifactSort = async () => {
  const response = await localInstance.get("artifact_sort/");
  return response.data;
};

// 프로젝트 정보 조회 - 트레이닝 된 프로젝트
export const getTrainedProjectDetail = async (user_email, project_id) => {
  const response = await localInstance.post("project_trained_detail/", {
    user_email: user_email,
    project_id: project_id,
  });
  return response.data;
};

// 프로젝트 정보 조회 - 트레이닝 안된 프로젝트 기본 정보
export const getProjectDetail = async (user_email, project_id) => {
  const response = await localInstance.post("project_info/", {
    user_email: user_email,
    project_id: project_id,
  });
  return response.data[0];
};

// 프로젝트 정보 조회 - 시각 모델
export const getImageModel = async (img_model_id) => {
  const response = await localInstance.post("get_img_model_name/", {
    img_model_id: img_model_id,
  });
  return response.data[0];
};

// 프로젝트 정보 조회 - 트레이닝 안된 프로젝트 기본 정보
export const getLanguageModel = async (lang_model_id) => {
  const response = await localInstance.post("get_lang_model_name/", {
    lang_model_id: lang_model_id,
  });
  return response.data[0];
};

//이미지 데이터를 삭제하는 함수
export const deleteImageData = async (ImgDataId, userEmail, projectId) => {
  const response = await localInstance.post("delete_img_data/", {
    img_data_id: ImgDataId,
    user_email: userEmail,
    project_id: projectId,
  });
  return response.data;
};

// 프로젝트를 삭제하는 함수
export const deleteProject = async (projectId) => {
  try {
    const response = await localInstance.delete(`project/${projectId}`);
    return response.data; // 삭제 성공 시 반환되는 데이터를 반환
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "프로젝트 삭제에 실패했습니다."
    );
  }
};

// 프로젝트 생성 api
export const createProject = async (
  projectName,
  projectDesc,
  selectedField,
  uploadFormat,
  resultTypes,
  apiKey,
  thumbnail,
  userEmail
) => {
  const response = await localInstance.post("insert_project/", {
    project_name: projectName,
    project_desc: projectDesc,
    project_sort: selectedField,
    project_data_sort: uploadFormat,
    artifact_selected: resultTypes,
    banya_api_key: apiKey,
    thum_image_url: thumbnail,
    user_email: userEmail,
  });
  return response.data;
};

//  파일업로드
export const uploadSingleFile = async (formData) => {
  const response = await formInstance.post("file_upload/", formData);
  return response.data;
};

// Function to fetch API list
export const fetchchatApiData = async () => {
  try {
    const response = await apiInstance.get("chat/apidata/list");
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data?.message || "API 리스트를 가져오는 데 실패했습니다."
      );
    } else {
      throw new Error("서버에 연결할 수 없습니다.");
    }
  }
};
