import React, { useState } from "react";
import styled from "styled-components";
import CustomModal from "../../components/CustomModal";

const ModalTitle = styled.h2`
  margin: 0 0 20px 0;
  font-size: 24px;
`;

const ModalInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const PermissionButton = styled.button`
  background-color: ${(props) => (props.selected ? "#7000FF" : "#efefef")};
  color: ${(props) => (props.selected ? "white" : "#333")};
  border: none;
  border-radius: 20px;
  padding: 10px 0;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
  flex: 1;

  &:hover {
    background-color: #d6d6d6;
  }
`;

const PermissionContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalButton = styled.button`
  background-color: #7000ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px;
  margin-bottom: 10px;

  &:hover {
    background-color: #5500cc;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(112, 0, 255, 0.5);
  }
`;

const CancelButton = styled(ModalButton)`
  background-color: #ccc;
  color: black;

  &:hover {
    background-color: #bbb;
  }
`;

const ModalText = styled.p`
  margin: 10px 0;
`;

const ApiKeyDisplay = styled.div`
  background: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  color: black;
  font-family: monospace;
`;

const PermissionsText = styled.p`
  margin-top: 30px;
  font-size: 13px;
`;

const PermissionsText2 = styled.p`
  margin-top: 10px;
  font-size: 13px;
`;

const CopyButton = styled.button`
  background-color: #7000ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-right: 10px;
`;

const DoneButton = styled.button`
  background-color: #efefef;
  color: #333;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ApiCreate = ({ onCloseApiCreate }) => {
  const [keyName, setKeyName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [selectedPermission, setSelectedPermission] = useState("All");
  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);

  const permissions = ["All", "Restricted", "Read Only"];

  // 여기 수정
  const generateRandomApiKey = () => {
    return (
      "BlbkFJcbRtIUly0A59c4-" +
      Math.random().toString(36).substring(2, 15) +
      "-" +
      Math.random().toString(36).substring(2, 15)
    );
  };

  const handleCreateSecretKey = () => {
    const newApiKey = generateRandomApiKey(); // 랜덤 API 키 생성
    setApiKey(newApiKey); // API 키 상태에 저장
    setIsKeyModalOpen(true); // 두 번째 모달 열기 (변경된 부분)
  };

  const handleCloseKeyModal = () => {
    onCloseApiCreate();
    setIsKeyModalOpen(false);
  };

  return (
    <>
      <CustomModal
        props={{ bc: "#333", width: "40%", display: "flex", fd: "column" }}
        onClose={() => onCloseApiCreate()}
      >
        <ModalTitle>비밀 키 생성</ModalTitle>
        <ModalInput
          type="text"
          placeholder="My api key name"
          value={keyName}
          onChange={(e) => setKeyName(e.target.value)}
        />
        <div>
          <span>Permissions</span>
          <PermissionContainer>
            {permissions.map((permission) => (
              <PermissionButton
                key={permission}
                selected={selectedPermission === permission}
                onClick={() => setSelectedPermission(permission)}
              >
                {permission}
              </PermissionButton>
            ))}

            {/* <Indicator selected={selectedPermission} /> */}
          </PermissionContainer>
        </div>
        <ModalButtonContainer>
          <CancelButton onClick={onCloseApiCreate}>Cancel</CancelButton>
          <ModalButton onClick={handleCreateSecretKey}>
            Create secret key
          </ModalButton>
        </ModalButtonContainer>
      </CustomModal>

      {isKeyModalOpen && (
        <CustomModal props={{ bc: "#333", width: "40%" }}>
          <ModalTitle>열쇠를 보관하세요</ModalTitle>
          <ModalText>
            이 비밀 키를 안전하고 접근하기 쉬운 곳에 보관하세요. 보안상의 이유로
            Banya 계정을 통해 다시 볼 수 없습니다. 이 비밀 키를 분실한 경우 새
            키를 생성해야 합니다.
          </ModalText>
          <ApiKeyDisplay>{apiKey}</ApiKeyDisplay>
          <PermissionsText>권한</PermissionsText>
          <PermissionsText2>API 리소스 읽기 및 쓰기</PermissionsText2>
          <ButtonContainer>
            <CopyButton
              onClick={() => {
                navigator.clipboard.writeText(apiKey); // API 키 복사
                alert("API 키가 복사되었습니다!");
              }}
            >
              키 복사
            </CopyButton>
            <DoneButton onClick={handleCloseKeyModal}>완료</DoneButton>
          </ButtonContainer>
        </CustomModal>
      )}
    </>
  );
};

export default ApiCreate;
