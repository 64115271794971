import React, { useEffect, useState, useRef, useMemo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { fetchchatApiData } from "../../api/ProjectApi";
import {
  useChat,
  Header,
  MessagesContainer,
  InputContainer,
  ChatBotContainer,
  ButtonCollections,
} from "loki-banya";

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
`;

const SelectField = styled.select`
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #222;
  color: white;
  margin-left: 30px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const SelectField2 = styled.select`
  width: 400px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #222;
  color: white;
  margin-left: 30px;
  transition: border-color 0.3s;
  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  gap: 60px;
`;

const SettingContainer = styled.div`
  background-color: #222;
  border-radius: 20px;
  padding: 20px;
  color: white;
  width: 1000px;
  margin: 20px 10px;
`;

const FormField = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Label = styled.label`
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
  margin-left: 30px;
  position: relative;
  display: flex;
  align-items: center;
  &::after {
    content: ${(props) => (props.required ? "'*'" : "''")};
    color: red;
    font-size: 20px;
    margin-left: 8px;
  }
`;

const UploadButton = styled.button`
  background-color: #7000ff;
  color: white;
  align-items: flex-end;
  margin-left: 200px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    background-color: #5a0c9e;
  }
`;

const UploadOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 10px;
`;

const UploadOptionButton = styled.button`
  background-color: ${(props) => (props.selected ? "#5a0c9e" : "#7000FF")};
  color: white;
  border: 1px solid ${(props) => (props.selected ? "#5a0c9e" : "#7000FF")};
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  margin: 0 20px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  &:hover {
    background-color: ${(props) => (props.selected ? "#4d009e" : "#5a0c9e")};
    border-color: ${(props) => (props.selected ? "#4d009e" : "#5a0c9e")};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const BackButton = styled.span`
  cursor: pointer;
  margin-top: 10px;
  font-size: 40px;
  margin-right: 10px;
`;

const Breadcrumb = styled.p`
  font-size: 14px;
  color: white;
  margin: 0 0 10px 10px;
`;

const PROJECT_ID = 1;
const project_id = PROJECT_ID;

const Testset = () => {
  const [uploadOption, setUploadOption] = useState("file");
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [error, setError] = useState("");
  const [datasetOptions, setDatasetOptions] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState("");
  const [apiData, setApiData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedTurns, setSelectedTurns] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("Korean");
  const navigate = useNavigate();

  const PRIVATE_KEY = "kid";

  const {
    messages,
    connected,
    addMessage,
    addComponent,
    handleSend,
    handleButton,
  } = useChat(PRIVATE_KEY);
  const messagesContainerRef = useRef(null);

  const buttonsData = useMemo(
    () => [
      { label: "🔧 Service Usage Guide", content: "Service Usage Guide" },
      { label: "🗣️ Consulting Request", content: "Consulting Request" },
      { label: "💰 Pricing Information", content: "Pricing Information" },
      { label: "💡 Success Stories", content: "Success Stories" },
    ],
    []
  );

  useEffect(() => {
    if (connected && messages.length === 0) {
      addMessage({
        text: "Hello! How can I help you?",
        isUser: false,
        timestamp: new Date().getTime(),
      });
      addComponent(() => (
        <ButtonCollections data={buttonsData} onSend={handleButton} />
      ));
    }
  }, [
    connected,
    messages.length,
    addMessage,
    addComponent,
    handleButton,
    buttonsData,
  ]);
  useEffect(() => {
    const loadApiData = async () => {
      try {
        const fetchedchatApiData = await fetchchatApiData();
        setApiData(fetchedchatApiData);
      } catch (error) {
        console.error("Error fetching API list:", error);
      }
    };

    loadApiData();
  }, []);

  useEffect(() => {
    const loadChatApiData = async () => {
      try {
        const data = await fetchchatApiData();
        const formattedData = data.map((item) => ({
          datasetName: item.datasetName,
          address: item.address,
        }));
        setDatasetOptions(formattedData);
      } catch (error) {
        console.error(
          "챗봇 API 데이터를 가져오는 중 오류가 발생했습니다:",
          error
        );
      }
    };
    loadChatApiData();
  });

  const handleUploadOptionChange = (option) => {
    setUploadOption(option);
  };
  const handleAddClick = async () => {
    if (uploadOption === "direct") {
      setIsChatOpen(true);
      setError("");
      addMessage({
        text: "직접 대화 모드에 들어왔습니다. 질문을 입력하세요.",
        isUser: false,
        timestamp: new Date().getTime(),
      });
    } else if (
      uploadOption === "auto" &&
      selectedDataset &&
      selectedTopic &&
      selectedTurns
    ) {
      setIsChatOpen(true);
      setError("");

      addMessage({
        text: `자동 대화 모드에 들어왔습니다. ${selectedTopic}에 대한 질문-대답이 ${selectedTurns}번 이루어집니다.`,
        isUser: false,
        timestamp: new Date().getTime(),
      });

      try {
        const questions = await generateQuestions(selectedTopic, selectedTurns);

        for (const question of questions) {
          addMessage({
            text: question,
            isUser: true,
            timestamp: new Date().getTime(),
          });

          const answer = await getChatBotResponse(question);
          addMessage({
            text: answer,
            isUser: false,
            timestamp: new Date().getTime(),
          });
        }
      } catch (error) {
        setError("질문 생성 중 오류가 발생했습니다.");
        console.error("Error generating questions:", error);
      }
    } else {
      setError("모든 필드를 입력해주세요.");
    }
  };

  const getChatBotResponse = async (question) => {
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`, // Insert your API key here
          },
          body: JSON.stringify({
            model: "gpt-4o",
            messages: [{ role: "user", content: question }],
            max_tokens: 150, // 응답 길이에 따라 조정98
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error("Failed to fetch answer: " + errorData.error.message);
      }

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      console.error("Error fetching chatbot response:", error);
      return "챗봇 응답을 가져오는 중 오류가 발생했습니다."; // 기본 오류 메시지
    }
  };

  const generateQuestions = async (topic, turns, retryCount = 0) => {
    const languagePrompt =
      selectedLanguage === "Korean"
        ? `Generate ${turns} questions related to ${topic} in Korean.`
        : `Generate ${turns} questions related to ${topic} in English.`;
    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`, // Insert your API key here
          },
          body: JSON.stringify({
            model: "gpt-4o",
            messages: [{ role: "user", content: languagePrompt }],
            max_tokens: 300,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          "Failed to fetch questions: " + errorData.error.message
        );
      }

      const data = await response.json();
      // 질문을 개행 문자로 분리하여 배열로 반환
      return data.choices[0].message.content.split("\n").filter((q) => q);
    } catch (error) {
      console.error("Error generating questions:", error);
      throw error;
    }
  };

  return (
    <>
      <MainContent>
        {apiData.map((api) => (
          <Breadcrumb
            key={api.datasetName}
            onClick={() => navigate(`/project/${project_id}`)}
          >
            {`프로젝트 목록 > ${api.datasetName} > 성능 테스트`}
          </Breadcrumb>
        ))}
        <TitleContainer>
          <BackButton onClick={() => navigate(`/project/${project_id}`)}>
            {"<"}
          </BackButton>
          {apiData.map((api) => (
            <Title key={api.datasetName}>{api.datasetName}</Title>
          ))}
        </TitleContainer>
        <Description>성능 테스트를 위한 기본 설정을 세팅해주세요.</Description>

        {/* 설정 영역 */}
        <SettingContainer>
          <UploadOptionContainer>
            <UploadOptionButton
              selected={uploadOption === "auto"}
              onClick={() => handleUploadOptionChange("auto")}
            >
              자동 대화 모드
            </UploadOptionButton>
            <UploadOptionButton
              selected={uploadOption === "direct"}
              onClick={() => handleUploadOptionChange("direct")}
            >
              직접 대화 모드
            </UploadOptionButton>
          </UploadOptionContainer>

          <FormField>
            <Label required>당신의 챗봇 API를 선택하세요.</Label>
            <SelectField2
              value={selectedDataset}
              onChange={(e) => setSelectedDataset(e.target.value)}
            >
              <option value="">API를 선택하세요</option>
              {datasetOptions.map((option, index) => (
                <option key={index} value={option.address}>
                  {option.datasetName}: {option.address}
                </option>
              ))}
            </SelectField2>
          </FormField>

          {uploadOption === "auto" && (
            <>
              <FlexContainer>
                <FormField>
                  <Label required>대화 주제</Label>
                  <SelectField
                    value={selectedTopic}
                    onChange={(e) => setSelectedTopic(e.target.value)}
                  >
                    <option value="">주제를 선택하세요</option>
                    <option value="행사/공연">행사/공연</option>
                    <option value="교육">교육</option>
                    <option value="헬스케어">헬스케어</option>
                    <option value="금융">금융</option>
                    <option value="여행">여행</option>
                    <option value="게임">게임</option>
                    <option value="소셜미디어">소셜미디어</option>
                    <option value="기타">기타</option>
                  </SelectField>
                </FormField>
                <FormField>
                  <Label required>대화 턴 수</Label>
                  <SelectField
                    value={selectedTurns}
                    onChange={(e) => setSelectedTurns(e.target.value)}
                  >
                    <option value="">턴 수를 선택하세요</option>
                    <option>1</option>
                    <option>10</option>
                    <option>30</option>
                    <option>50</option>
                    <option>무제한</option>
                  </SelectField>
                </FormField>
                <FormField>
                  <Label required>언어 선택</Label>
                  <SelectField
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                  >
                    <option value="Korean">한국어</option>
                    <option value="English">영어</option>
                  </SelectField>
                </FormField>
              </FlexContainer>
            </>
          )}
          <ButtonContainer>
            <UploadButton onClick={handleAddClick}>
              테스트 실행하기
            </UploadButton>
          </ButtonContainer>
        </SettingContainer>

        {/* ChatBox 또는 ChatBotContainer 출력 */}
        {isChatOpen && (
          <>
            <ChatBotContainer
              config={{
                width: "500px",
                height: "800px",
                top: "10px",
                left: "10px",
                bordeRadius: "10px",
              }}
            >
              <Header config={{ height: "60px" }} />
              <MessagesContainer
                ref={messagesContainerRef}
                messages={messages}
                config={{
                  container: { backgroundColor: "#fff" },
                  bubble: { user: { color: "black" } },
                }}
              />
              <InputContainer
                config={{
                  input: { placeholderConnected: "Message" },
                  color: "white",
                }}
                onSend={handleSend}
                connected={connected}
              />
            </ChatBotContainer>
          </>
        )}
      </MainContent>
      {error && <p style={{ color: "red", marginLeft: "30px" }}>{error}</p>}
    </>
  );
};

export default Testset;
