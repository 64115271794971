import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
`;

const DatasetSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const DatasetBox = styled.div`
  flex: 1;
  height: 220px;
  background-color: #f8f9fa;
  padding: 20px;
  margin: 0 10px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const DatasetTitle = styled.h2`
  color: black;
  font-size: 28px;
  margin-bottom: 15px;
`;

const DatasetDescription = styled.p`
  font-size: 16px;
  color: #555;
`;

const DatasetCount = styled.span`
  display: block;
  font-size: 14px;
  color: #888;
  margin-top: 100px;
  text-align: right;
`;

const ProjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #222;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
`;

const ProjectDetail = styled.p`
  margin: 10px 0;
  margin-left: 10px;
  line-height: 1.8;
`;

const Heading = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
`;

const Dataset = () => {
  const navigate = useNavigate();

  // Example counts
  const imageDatasetCount = 5; // 이미지 데이터셋 수
  const textDatasetCount = 3; // 텍스트 데이터셋 수

  return (
    <MainContent>
      <Title>데이터셋함</Title>
      <Description>API 생성을 위해 데이터셋을 넣어주세요.</Description>
      <ProjectInfoContainer>
        <ProjectDetail>
          <Heading> 사용 방법</Heading>
          <br />
          - 최종 사용자용 API를 생성하기 위해서는 아래의 데이터셋함에서 텍스트,
          이미지 데이터를 업로드 후 학습시킬 데이터들을 선택하여 나의 프로젝트에
          할당합니다.
          <br />- 텍스트 또는 이미지 데이터셋 리스트에서 선택한 데이터셋이 특정
          프로젝트에 할당되도록 합니다.
        </ProjectDetail>
      </ProjectInfoContainer>
      <DatasetSection>
        <DatasetBox onClick={() => navigate("/dataset/img")}>
          <DatasetTitle>이미지 데이터셋함</DatasetTitle>
          <DatasetDescription>
            이미지 데이터를 업로드하고 관리할 수 있습니다.
          </DatasetDescription>
          <DatasetCount>총 {imageDatasetCount}개의 이미지 데이터</DatasetCount>
        </DatasetBox>

        <DatasetBox onClick={() => navigate("/dataset/txt/")}>
          <DatasetTitle>텍스트 데이터셋함</DatasetTitle>
          <DatasetDescription>
            텍스트 데이터를 업로드하고 관리할 수 있습니다.
          </DatasetDescription>
          <DatasetCount>총 {textDatasetCount}개의 텍스트 데이터</DatasetCount>
        </DatasetBox>
      </DatasetSection>
    </MainContent>
  );
};

export default Dataset;
