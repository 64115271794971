import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ApiCreate from "./ApiCreate.js";
import { getApiList } from "../../api/MypageApi.js";

const Title = styled.h1`
  font-size: 2rem;
`;

const ApiKeyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  background-color: #333;
  color: white;
  padding: 20px;
  border-radius: 10px;
`;

const CreateApiKeyButton = styled.button`
  background-color: #7000ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5500cc;
  }
`;

const ListContainer = styled.section`
  margin-top: 30px;
  border-radius: 10px;
  padding: 30px;
  background-color: #fff;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;
`;

const GridContainer = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 0.1fr repeat(7, 1fr); /* 8열 테이블 */

  background-color: #f8f8f8;
  border-radius: 8px;
  overflow: scroll;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media (max-width: 480px) {
    font-size: 0.5rem;
  }
`;

const GridHeader = styled.div`
  background-color: #333;
  color: white;
  padding: 0.75rem 0.5rem;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #ddd;
`;

const GridItem = styled.div`
  padding: 0.75rem 0.5rem;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #333;
  &:nth-child(8n) {
    border-right: none; /* 마지막 열 구분 */
  }
  &:nth-child(-n + 8) {
    background-color: #333; /* 헤더 색상 */
    color: white;
    font-weight: bold;
  }
`;

const EmptyApi = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin: 1rem 0;
  grid-column: 1 / -1; /* Span all columns */
  padding: 1.5rem;
`;

const PROJECT_ID = 1;
const USER_EMAIL = "first@daiblab.com";

const Myapi = () => {
  const [selectedApis, setSelectedApis] = useState(new Set());
  const [apiList, setApiList] = useState([]);
  const [apiCreateModal, setApiCreateModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getApiList(USER_EMAIL)
      .then((res) => setApiList(res))
      .catch((err) => console.error(err));
  }, []);

  const handleApiRowClick = (datasetName) => {
    setSelectedApis((prev) =>
      prev.has(datasetName)
        ? new Set([...prev].filter((name) => name !== datasetName))
        : new Set(prev.add(datasetName))
    );
  };

  return (
    <>
      <Title>API 리스트</Title>
      <ApiKeyContainer>
        <p>Banya 에 엑세스 하기 위해서는 API 키를 발급 받아야 합니다.</p>
        <CreateApiKeyButton onClick={() => setApiCreateModal(true)}>
          API 키 발급
        </CreateApiKeyButton>
      </ApiKeyContainer>

      <ListContainer>
        <h2>에이젼트 API 리스트</h2>
        <p>
          하나 이상의 에이젼트를 사용하시려면 새로운 프로젝트를 생성하시기
          바랍니다.
        </p>
        <ButtonContainer>
          <CreateApiKeyButton onClick={() => navigate(`/project/add`)}>
            새 프로젝트
          </CreateApiKeyButton>
          <CreateApiKeyButton
            onClick={() => navigate(`/project/${PROJECT_ID}/chatset`)}
          >
            웹앱 디자인하기
          </CreateApiKeyButton>
          <CreateApiKeyButton
            onClick={() => navigate(`/project/${PROJECT_ID}/testset`)}
          >
            성능 테스트하기
          </CreateApiKeyButton>
        </ButtonContainer>
        <GridContainer>
          <GridHeader></GridHeader>
          <GridHeader>프로젝트 이름</GridHeader>
          <GridHeader>API 주소</GridHeader>
          <GridHeader>Input Params</GridHeader>
          <GridHeader>호출 방식</GridHeader>
          <GridHeader>학습 상태</GridHeader>
          <GridHeader>학습에 걸린 시간 (Seconds)</GridHeader>
          <GridHeader>현재까지 호출 횟수</GridHeader>
          {apiList.length > 0 ? (
            apiList.map((api) => (
              <React.Fragment
                key={api.datasetName}
                onClick={() => handleApiRowClick(api.datasetName)}
                selected={selectedApis.has(api.datasetName)}
              >
                <GridItem>
                  <input checked={selectedApis.has(api.datasetName)} readOnly />
                </GridItem>
                <GridItem>{api.datasetName}</GridItem>
                <GridItem>{api.api_url}</GridItem>
                <GridItem>{api.inputParams}</GridItem>
                <GridItem>{api.call_method}</GridItem>
                <GridItem style={{ color: getStatusColor(api.status) }}>
                  {api.status}
                </GridItem>
                <GridItem>{api.timeSpent}</GridItem>
                <GridItem>{api.api_call_count}</GridItem>
              </React.Fragment>
            ))
          ) : (
            <EmptyApi>API가 없습니다.</EmptyApi>
          )}
        </GridContainer>
      </ListContainer>
      {apiCreateModal && (
        <ApiCreate onCloseApiCreate={() => setApiCreateModal(false)} />
      )}
    </>
  );
};

// Status 색상 함수 분리
const getStatusColor = (status) => {
  if (status === "학습 완료") return "blue";
  if (status === "학습 진행중") return "orange";
  if (status === "학습 전") return "red";
  return "#333";
};

export default Myapi;
