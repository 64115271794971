import React from "react";
import styled from "styled-components";

const MainContent = styled.main`
  flex: 1;
  border-radius: 10px;
  padding: 30px;
  background-color: #f5f5f5;
`;

const Title = styled.h1`
  font-size: 36px;
  margin: 5px 0;
  color: #333;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 20px;
  margin-left: 5px;
  color: #555;
`;

const ProjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #222;
  border-radius: 20px;
  padding: 25px;
  margin-top: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

const ProjectDetail = styled.p`
  margin: 10px 0;
  line-height: 1.6;
  font-size: 18px;
  color: #eee;
`;

const Analysis = () => {
  return (
    <MainContent>
      <Title>반야란?</Title>
      <Description>반야 설명서입니다.</Description>

      <ProjectInfoContainer>
        <ProjectDetail>
          - 멀티모달 AI 를 만들 수 있는 다양한 API 를 제공하는 클라우드 서비스
          입니다. 자체 LLM 부터, 이미지 인식, 이미지 생성을 특정 지식 도메인
          기준으로 추론하여 동작하는 있는 파운데이션 모델을 직접 만들거나
          파인튜닝 할 수 있는 클라우드 도구를 제공합니다.
          <br />
          <br />
          - 또한 반야는 매트릭스 팩토리제이션 머신러닝 기법을 사용한 사용자
          맞춤형 상품, 컨텐츠 추천 API 를 만드는 기능도 제공합니다.
          <br />
          <br />- 반야 엔터프라이즈 솔루션을 사용하면 클라우드 뿐만 아니라
          온프라미스로 멀티 모달 AI 를 만들 수 있는 토탈 인공지능 개발 환경을
          내재화 할 수 있습니다.
        </ProjectDetail>
      </ProjectInfoContainer>
    </MainContent>
  );
};

export default Analysis;
