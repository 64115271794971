import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.nav`
  width: 260px;
  background-color: #222;
  padding: 20px;
  position: fixed;
  top: 100px;
  left: 0;
  height: calc(100vh - 80px);
  overflow-y: auto;
`;

const SidebarList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SidebarItem = styled.li`
  margin: 0.8rem 0;
  padding: 0.9rem 1rem;
  background-color: ${(props) => (props.active ? "white" : "transparent")};
  color: ${(props) => (props.active ? "#7000FF" : "inherit")};
  border-radius: 10rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    color: #ae5dff;
  }
`;

const Subject = styled.li`
  font-size: 24px;
  margin: 20px 0;
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "white" : "transparent")};
  color: ${(props) => (props.active ? "#7000FF" : "inherit")};
  border-radius: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
`;

const FooterText = styled(Link)`
  color: gray;
  margin-left: 30px;
  font-size: 14px;
  text-decoration: none;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  &:hover {
    color: #ae5dff;
  }
`;

const Arrow = styled.span`
  margin-left: 1px;
  transition: transform 0.3s;
  transform: ${(props) => (props.open ? "rotate(0deg)" : "rotate(360deg)")};
`;

// const Separator = styled.hr`
//   border: 0;
//   height: 2px;
//   background: #555;
//   margin: 20px 0;
// `;

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState(null);

  const sidebarItems = [
    { name: "프로젝트", path: "/project" },
    { name: "데이터셋", path: "/dataset" },
    { name: "나의 API 리스트", path: "/mypage/api" },
  ];

  const handleSidebarItemClick = (item) => {
    if (activeItem !== item) {
      setActiveItem(item); // 다른 항목을 클릭했을 때만 활성화 항목 변경
    }
  };

  return (
    <Container>
      <Subject>멀티모달 AI</Subject>
      <SidebarList>
        {sidebarItems.map((item) => (
          <SidebarItem
            key={item.name}
            active={activeItem === item.name}
            onClick={() => handleSidebarItemClick(item.name)}
          >
            <StyledLink to={item.path}>{item.name}</StyledLink>
            {activeItem === item.name && <Arrow open>{">"}</Arrow>}
          </SidebarItem>
        ))}
      </SidebarList>

      {/* <Separator />

      <Subject onClick={() => handleRecommendationClick("AI 추천 시스템")}>
        AI 추천 시스템
      </Subject>
      <SidebarList>
        <SidebarItem
          active={activeRecommendation === "데이터베이스"}
          onClick={() => handleRecommendationClick("데이터베이스")}
        >
          데이터베이스
          <Arrow open={activeRecommendation === "데이터베이스"}>{">"}</Arrow>
        </SidebarItem>
        {activeRecommendation === "데이터베이스" && (
          <ul>
            <StyledLink to="/database/add">
              <SubItem>DB 추가하기</SubItem>
            </StyledLink>
            <StyledLink to="/database">
              <SubItem>DB 리스트</SubItem>
            </StyledLink>
          </ul>
        )}
        <SidebarItem
          active={activeRecommendation === "추천 API 리스트"}
          onClick={() => handleRecommendationClick("추천 API 리스트")}
        >
          API
          <Arrow open={activeRecommendation === "추천 API 리스트"}>
            {">"}
          </Arrow>
        </SidebarItem>
      </SidebarList> */}

      <FooterText to="/descript">반야(banya)란?</FooterText>
    </Container>
  );
};

export default Sidebar;
