import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 50%;
  position: relative;
`;

const Button = styled.div`
  padding: 0.6rem 1rem;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #444;
  color: white;
  cursor: pointer;
  &:hover {
    border-color: #7000ff;
  }
`;

const List = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #333;
  background-color: #222;
  color: white;
  border-radius: 5px;
  z-index: 10;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ListItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #333;
  }
`;

const Dropdown = ({ options, value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  // 선택된 값의 라벨을 가져오기
  const selectedLabel =
    options.find((option) => option.value === value)?.label || placeholder;

  return (
    <Container>
      <Button onClick={handleToggleDropdown}>
        {selectedLabel || "Select an option"}
      </Button>
      {isOpen && (
        <List>
          {options.map((option) => (
            <ListItem
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
};

export default Dropdown;
