import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  createProject,
  getProjectDetailReady,
  uploadSingleFile,
} from "../../api/ProjectApi.js";
import { getUserApiKey } from "../../api/MypageApi.js";
import pictureIcon from "./img/picture.svg";
import Dropdown from "../../components/Dropdown.js";
import Modal from "../../components/Modal.js";

const Breadcrumb = styled.p`
  font-size: 1rem;
  color: white;
  margin-bottom: 0.5rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 1rem 0;
`;

const ProjectForm = styled.div`
  background-color: #333;
  border-radius: 20px;
  padding: 2rem;
  color: white;
`;

const FormField = styled.div`
  margin-bottom: 1.3rem;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  &::after {
    content: ${(props) => (props.required ? "'*'" : "''")};
    color: red;
    font-size: 1.25rem;
    margin-left: 0.7rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.6rem 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #444;
  color: #fff;
  transition: border-color 0.3s;

  &:focus {
    border-color: #5133bb;
    outline: none;
  }
  &::placeholder {
    color: #fff;
    font-size: 0.9rem;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #444;
  color: white;
  height: 150px;
  resize: vertical;
  transition: border-color 0.3s;

  &:focus {
    border-color: #5133bb;
    outline: none;
  }
  &::placeholder {
    color: #fff;
  }
`;

const Subtext = styled.p`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: white;
`;

const CheckboxGroup = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  background-color: ${(props) =>
    props.selected ? "rgba(112, 0, 255, 0.2)" : "transparent"};
  border: ${(props) =>
    props.selected ? "1px solid #5133BB" : "1px solid #555"};
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  flex: 1 1 auto;
`;

const Checkbox = styled.input`
  margin-right: 1rem;
  accent-color: ${(props) => (props.selected ? "#5133BB" : "#555")};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const Button = styled.button`
  min-width: 5rem;
  height: 2.5rem;
  background-color: #5133bb;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
`;

const GuideWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

const ImageIcon = styled.img`
  width: 5.95rem;
`;
const ImagePreview = styled.img`
  width: 5.95rem;
  height: 5.95rem;
  object-fit: cover;
  background-color: #fff;
  border-radius: 10px;
`;

const GuideText = styled.div`
  color: white;
  font-size: 14px;
  line-height: 1.5;
  background-color: #555;
  border-radius: 10px;
  padding: 1rem 1.5rem;
`;

const USER_EMAIL = "first@daiblab.com";
const userEmail = USER_EMAIL;

const Addproject = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  // 기본 정보 불러오기
  const [settings, setSettings] = useState({
    project_sort: [], // 교육, ..
    project_data_sort: [], // 텍스트 + 이미지, ..
    artifact_sort: [], // 데탑용 웹,...
  });

  // 폼 제출할 것들
  const [thumbnail, setThumbnail] = useState(null);
  const [projectName, setProjectTitle] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [selectedField, setselectedField] = useState(""); //project_sort
  const [uploadFormat, setUploadFormat] = useState(""); // project_data_sort
  const [resultTypes, setResultTypes] = useState([]); // artifact_sort
  const [apiKey, setApiKey] = useState("");

  // 옵션이 요구하는 형식으로 변경(드롭박스용)
  const [projectSort, setProjectSort] = useState([]);
  const [projectDataSort, setProjectDataSort] = useState([]);
  // (체크박스)
  const [artifactSort, setArtifactSort] = useState([]);

  const handleCheckboxChange = (type) => {
    setResultTypes((prev) => {
      if (prev.includes(type)) {
        return prev.filter((item) => item !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // 파일 선택
    const formData = new FormData();
    formData.append("file", file);

    uploadSingleFile(formData)
      .then((res) => {
        setThumbnail(res.file_url);
        setImagePreview(URL.createObjectURL(file)); // 미리보기용 URL 생성
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async () => {
    if (
      !projectName ||
      !projectDesc ||
      !projectSort ||
      !projectDataSort ||
      !artifactSort ||
      !apiKey
    ) {
      alert("필수 항목을 입력해주세요.");
      setIsModalOpen(false);
      return;
    }

    createProject(
      projectName,
      projectDesc,
      selectedField,
      uploadFormat,
      resultTypes.join(":"),
      apiKey,
      thumbnail,
      userEmail
    )
      .then((res) => {
        setIsModalOpen(false); // 모달 닫기
        const createdProjectId = res.project_id;
        navigate(`/project/${createdProjectId}`); // 페이지 이동
      })
      .catch((err) => console.log(err));
  };

  const renameList = (inputList, setNewList) => {
    const newList = inputList.map((item) => ({
      value: item.id,
      label: item.artifact_sort_name,
    }));
    setNewList(newList);
  };

  useEffect(() => {
    // 기본 정보 불러오기
    getProjectDetailReady()
      .then((res) => {
        setSettings(res);
      })
      .catch((err) => console.log(err));
    getUserApiKey(userEmail)
      .then((res) => setApiKey(res.api_key))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    renameList(settings.project_sort, setProjectSort);
    renameList(settings.project_data_sort, setProjectDataSort);
    setArtifactSort(settings.artifact_sort);
  }, [settings]);

  return (
    <>
      <Breadcrumb
        onClick={() => navigate("/project")}
      >{`프로젝트 목록 > 프로젝트 생성`}</Breadcrumb>
      <Title>프로젝트 생성</Title>

      <ProjectForm>
        <FormField>
          <Label required>프로젝트 제목</Label>
          <Input
            type="text"
            placeholder="프로젝트 제목을 입력하세요."
            value={projectName}
            onChange={(e) => setProjectTitle(e.target.value)}
          />
        </FormField>
        <FormField>
          <Label required>프로젝트 설명</Label>
          <Textarea
            placeholder="프로젝트에 대한 상세 설명을 입력해주세요."
            value={projectDesc}
            onChange={(e) => setProjectDesc(e.target.value)}
          />
        </FormField>
        <FormField>
          <Label required>프로젝트 분야</Label>
          <Dropdown
            options={projectSort}
            value={selectedField}
            onChange={(value) => setselectedField(value)}
            placeholder="프로젝트 분야 선택"
          />
        </FormField>
        <FormField>
          <Label required>학습 데이터 형식</Label>
          <Dropdown
            options={projectDataSort}
            value={uploadFormat}
            onChange={(value) => setUploadFormat(value)}
            placeholder="학습 데이터 형식 선택"
          />
        </FormField>
        <FormField>
          <Label required>결과물 형식</Label>
          <Subtext>중복 선택이 가능합니다</Subtext>
          <CheckboxGroup>
            {artifactSort.map((item) => (
              <CheckboxContainer
                key={item.id}
                selected={resultTypes.includes(item.id)}
                onClick={() => handleCheckboxChange(item.id)}
              >
                <Checkbox
                  type="checkbox"
                  checked={resultTypes.includes(item.id)}
                  readOnly
                />
                {item.artifact_sort_name}
              </CheckboxContainer>
            ))}
          </CheckboxGroup>
        </FormField>
        <FormField>
          <Label>API KEY 등록</Label>
          <Subtext>
            API 키는 프로필 아이콘 메뉴의 API에서 확인할 수 있습니다. 키를 새로
            생성하실 분들은 아래 생성 버튼을 클릭해 주세요.
          </Subtext>
          <ButtonWrapper>
            <Input
              type="text"
              placeholder={apiKey || "API KEY가 생성되면 여기에 보이게 됩니다."}
            />
            {/* 생성 모달 띄우기 */}
            <Button disabled={!!apiKey} onClick={() => alert("생성하기")}>
              생성
            </Button>
          </ButtonWrapper>
        </FormField>
        <FormField>
          <Label>대표 이미지</Label>
          <Subtext>
            메인 프로젝트 이미지 및 노출할 대표 이미지를 등록해주세요.
          </Subtext>
          <GuideWrapper>
            <ImageIcon src={pictureIcon} alt="대표 이미지" />
            {imagePreview && <ImagePreview src={imagePreview} alt="미리보기" />}

            <GuideText>
              <p>대표 이미지 등록 가이드</p>
              <ul>
                <li>10MB 이하의 JPG, JPEG, PNG 파일</li>
                <li>해상도 385*480픽셀 / 72dpi 이상</li>
              </ul>
            </GuideText>
          </GuideWrapper>
          <Input type="file" onChange={handleImageChange} />
        </FormField>

        <Button onClick={() => setIsModalOpen(true)}>생성하기</Button>
      </ProjectForm>
      {isModalOpen && (
        <Modal
          title={`프로젝트를 생성하시겠습니까?`}
          onConfirm={handleSubmit}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default Addproject;
