import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import GlobalStyle from "./components/GlobalStyle";
import Landing from "./pages/Landing.js";
import Wrapper from "./components/Wrapper.js";
import SignUp from "./pages/auth/SignUp.js";
import ProjectList from "./pages/chatbot/ProjectList.js";
import Addproject from "./pages/chatbot/Addproject.js";
import ProjectDetail from "./pages/chatbot/ProjectDetail.js";
import Dataset from "./pages/chatbot/Dataset.js";
import Imgdata from "./pages/chatbot/Imgdata.js";
import Txtdata from "./pages/chatbot/Txtdata.js";
import Addimg from "./pages/chatbot/Addimg.js";
import Addtxt from "./pages/chatbot/Addtext.js";
import Aiset from "./pages/chatbot/Aiset.js";
import Testset from "./pages/chatbot/Testset.js";
import Chatset from "./pages/chatbot/Chatset.js";
import Database from "./pages/cfml/Database.js";
import Mydatabase from "./pages/cfml/Mydatabase.js";
import Learning from "./pages/cfml/Learning.js";
import Descript from "./pages/chatbot/Descript.js";
import Charge from "./pages/mypage/Charge.js";
import Myapi from "./pages/mypage/Myapi.js";
import Profile from "./pages/chatbot/Profile.js";
import "./locales/i18n";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/" element={<Wrapper />}>
          <Route path="" exact element={<Navigate replace to="/project" />} />
          <Route path="/project" element={<ProjectList />} />
          <Route path="/project/add" element={<Addproject />} />
          <Route path="/project/:project_id" element={<ProjectDetail />} />
          <Route path="/project/:project_id/aiset" element={<Aiset />} />
          <Route path="/project/:project_id/testset" element={<Testset />} />
          <Route path="/project/:project_id/chatset" element={<Chatset />} />

          <Route path="/descript" element={<Descript />} />
          <Route path="/mypage" element={<Profile />} />
          <Route path="/mypage/charge" element={<Charge />} />
          <Route path="/mypage/api" element={<Myapi />} />

          <Route path="/dataset" element={<Dataset />} />
          <Route path="/dataset/img" element={<Imgdata />} />
          <Route path="/dataset/txt" element={<Txtdata />} />
          <Route path="/dataset/img/add" element={<Addimg />} />
          <Route path="/dataset/txt/add" element={<Addtxt />} />

          <Route path="/database" element={<Mydatabase />} />
          <Route path="/database/add" element={<Database />} />
          <Route path="/learning" element={<Learning />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
