import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FileIcon from "./img/file.svg";

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
`;

const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
`;

const SettingContainer = styled.div`
  background-color: #222;
  border-radius: 20px;
  padding: 20px;
  color: white;
  width: 1000px;
  margin: 20px 10px;
`;

const FormField = styled.div`
  margin-bottom: 30px;
`;

const Label = styled.label`
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: ${(props) => (props.required ? "'*'" : "''")};
    color: red;
    font-size: 20px;
    margin-left: 8px;
  }
`;

const Input = styled.input`
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #333;
  color: white;
  transition: border-color 0.3s;

  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;

const TagInput = styled(Input)`
  display: inline-block;
  width: auto;
  margin-right: 10px;
`;

const UploadButton = styled.button`
  background-color: #7000ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #5a0c9e;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const ImageIcon = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 20px;
`;

const ImageGuideContainer = styled.div`
  width: 350px;
  height: 80px;
  display: flex;
  flex-direction: column;
  background-color: #555;
  border-radius: 10px;
  padding: 10px;
`;

const GuideText = styled.div`
  color: white;
  font-size: 14px;
  margin-left: 15px;
  line-height: 1.5;
  flex: 1;
`;

const Subtext = styled.p`
  font-size: 14px;
  color: white;
  margin-top: -5px;
  margin-bottom: 18px;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const Tag = styled.div`
  background-color: #7000ff;
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const RemoveTag = styled.span`
  background-color: #5a0c9e;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
`;

const FileInput = styled.input`
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #555;
  background-color: #222;
  color: white;
  transition: border-color 0.3s;

  &:focus {
    border-color: #7000ff;
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Guide2 = styled.div`
  width: 730px;
  height: 100px;
  display: flex;
  flex-direction: column;
  background-color: #555;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
`;

const ModalTitle = styled.div`
  color: black;
  font-size: 20px;
  margin-bottom: 20px;
`;

const ModalButtonContainer = styled.div`
  margin-top: 20px;
`;

const ModalButton = styled.button`
  background-color: ${(props) => (props.primary ? "#5500CC" : "#ddd")};
  color: ${(props) => (props.primary ? "white" : "black")};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.primary ? "#5500aa" : "#ccc")};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const BackButton = styled.span`
  cursor: pointer;
  margin-top: 10px;
  font-size: 40px;
  margin-right: 10px;
`;

const Breadcrumb = styled.p`
  font-size: 14px;
  color: white;
  margin: 0 0 10px 10px;
`;

const Addimg = () => {
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [filePreviews, setFilePreviews] = useState([]);
  const [label, setLabel] = useState("");
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      const trimmedInput = tagInput.trim();

      if (trimmedInput !== "" && !tags.includes(trimmedInput)) {
        const newTags = trimmedInput
          .split(/\s+/)
          .filter((tag) => tag.trim() !== "");
        setTags((prevTags) => [...prevTags, ...newTags]);
        setTagInput("");
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);

    const previews = [];
    let readFilesCount = 0;

    selectedFiles.forEach((file, i) => {
      if (i < 5) {
        const reader = new FileReader();
        reader.onloadend = () => {
          previews.push(reader.result);
          readFilesCount++;
          if (readFilesCount === Math.min(selectedFiles.length, 5)) {
            setFilePreviews(previews);
          }
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const handleSubmit = async () => {
    if (!label || !files || !tagInput) {
      alert("필수 항목을 입력해주세요.");
      setIsModalOpen(false);
      navigate(`/dataset/img`); // 페이지 이동
      return;
    }
  };

  return (
    <MainContent>
      <Breadcrumb
        onClick={() => navigate("/dataset/img")}
      >{`데이터셋함 > 이미지 데이터셋 리스트 > 업로드하기`}</Breadcrumb>
      <TitleContainer>
        <BackButton onClick={() => navigate("/dataset/img")}>{"<"}</BackButton>
        <Title>이미지 데이터셋 업로드</Title>
      </TitleContainer>
      <Description>
        업로드할 이미지 폴더에 대한 정보를 입력해주세요.
      </Description>
      <SettingContainer>
        <FormField>
          <Label required>제목</Label>
          <Subtext>업로드할 이미지 폴더에 대한 제목을 설정해주세요.</Subtext>
          <Input
            type="text"
            placeholder="제목을 입력하세요."
            value={label}
            onChange={(e) => setLabel(e.target.value)} // 라벨 입력 처리
          />
        </FormField>
        <FormField>
          <Label>태그 설정</Label>
          <Guide2>
            <GuideText>
              <ul>
                <li>
                  · 태그의 설정은 인공지능이 당신의 지식체계를 개념적으로
                  이해하게 하기 위한 중요한 수단 입니다.
                </li>
                <li>· 태그는 해시태그처럼 여러개를 설정할 수 있습니다.</li>
                <li>
                  · 학습 효율을 높이기 위해서는 질문 대답과 연관성이 높은 태그를
                  설정 하세요.
                </li>
                <p>
                  예시) 라벨 : 주문 방법, 사용 방법, 자주하는 질문, 고객관리
                </p>
              </ul>
            </GuideText>
          </Guide2>
          <TagInput
            type="text"
            value={tagInput}
            onChange={handleTagInputChange}
            onKeyDown={handleTagInputKeyDown}
            placeholder="태그를 입력하세요."
          />
          <TagList>
            {tags.map((tag, index) => (
              <Tag key={index}>
                {tag}
                <RemoveTag onClick={() => handleRemoveTag(tag)}>×</RemoveTag>
              </Tag>
            ))}
          </TagList>
        </FormField>
        <FormField>
          <Label>폴더 업로드</Label>
          <Subtext>이미지 폴더를 업로드해주세요.</Subtext>
          <ImageWrapper>
            <ImageIcon src={FileIcon} alt="대표 이미지" />
            <ImageGuideContainer>
              <GuideText>
                <p>이미지 폴더 업로드 가이드</p>
                <ul>
                  <li>· 10MB 이하의 JPG, JPEG, PNG 파일을 포함한 폴더</li>
                  <li>· 해상도 385*480픽셀 / 72dpi 이상</li>
                </ul>
              </GuideText>
            </ImageGuideContainer>
          </ImageWrapper>
          <FileInput
            type="file"
            webkitdirectory="true"
            directory="true"
            multiple
            onChange={handleFileChange}
          />
        </FormField>

        {filePreviews.length > 0 && (
          <FormField>
            <Label>미리보기</Label>
            <Subtext>업로드한 이미지 파일 미리보기입니다.</Subtext>
            <TagList>
              {filePreviews.map((preview, index) => (
                <Tag key={index}>
                  <img
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "1px",
                    }}
                  />
                </Tag>
              ))}
            </TagList>
          </FormField>
        )}

        <ButtonContainer>
          <UploadButton onClick={handleAddClick}>업로드하기</UploadButton>
        </ButtonContainer>
      </SettingContainer>

      {isModalOpen && (
        <ModalBackdrop>
          <ModalContainer>
            <ModalTitle>데이터셋을 업로드하시겠습니까?</ModalTitle>
            <ModalButtonContainer>
              <ModalButton primary={true} onClick={handleSubmit}>
                예
              </ModalButton>
              <ModalButton primary={false} onClick={handleCloseModal}>
                아니오
              </ModalButton>
            </ModalButtonContainer>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </MainContent>
  );
};

export default Addimg;
