import { apiInstance, localInstance } from "./Axios.js";

export const fetchuserData = async () => {
  try {
    const response = await apiInstance.get("auth/user/profile");
    console.log("API Response Data:", response.data); // 응답 데이터 출력
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(
        error.response.data?.message || "User data를 가져오는 데 실패했습니다."
      );
    } else {
      throw new Error("서버에 연결할 수 없습니다.");
    }
  }
};

// api key 조회
export const getUserApiKey = async (user_email) => {
  const response = await localInstance.post("get_user_api/", {
    user_email: user_email,
  });
  return response.data[0];
};

// API list 조회
export const getApiList = async (user_email) => {
  const response = await localInstance.post("user_api_list/", {
    user_email: user_email,
  });
  return response.data;
};
