import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { fetchdbData, fetchdbtableData, fetchdbmergeData } from "../../api";

// Main content styling
const MainContent = styled.main`
  flex: 1;
  padding: 20px;
  color: white;
`;

// Title styles
const Title = styled.h1`
  font-size: 35px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const Title2 = styled.h1`
  font-size: 25px;
  margin-left: 10px;
  margin-top: 10px;
`;

// Description styles
const Description = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-bottom: 30px;
  margin-left: 10px;
`;

const Description2 = styled.h1`
  font-size: 18px;
  margin-left: 30px;
  margin-top: 24px;
`;

// White-themed table styling with rounded outer corners
const WhiteTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  background-color: #fff; /* White background for the table */
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure rounded corners are visible */
`;

const WhiteTableHeader = styled.th`
  background-color: #f4f4f4; /* Light gray background for header */
  color: #333; /* Dark text color */
  padding: 12px;
  border-bottom: 2px solid #ddd; /* Light gray border */
  font-weight: bold;
  border-top-left-radius: 8px; /* Rounded top-left corner */
  border-top-right-radius: 8px; /* Rounded top-right corner */
`;

const WhiteTableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9; /* Slightly gray background for even rows */
  }

  &:nth-child(odd) {
    background-color: #fff; /* White background for odd rows */
  }
`;

const WhiteTableCell = styled.td`
  padding: 12px;
  color: #333; /* Dark text color for readability */
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #ddd; /* Light gray border for cell separation */
  border-left: 1px solid #ddd; /* Light gray border on the left */

  /* Remove rounded corners from cells */
  &:first-child {
    border-top-left-radius: 0;
  }

  &:last-child {
    border-top-right-radius: 0;
  }
`;

// Original table styling with rounded outer corners for detailed view
const DetailedTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 20px;
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Ensure rounded corners are visible */
`;

const DetailedTableHeader = styled.th`
  background-color: #333;
  color: white;
  padding: 12px;
  border-right: 1px solid #555;

  &:last-child {
    border-right: none;
  }
`;

const DetailedTableRow = styled.tr`
  background-color: #222;
  &:nth-child(even) {
    background-color: #333;
  }
`;

const DetailedTableCell = styled.td`
  padding: 12px;
  color: white;
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #555;

  /* Remove border for the last cell in each row */
  &:last-child {
    border-right: none; /* 마지막 셀의 오른쪽 경계선 제거 */
  }

  /* Remove rounded corners from cells */
  &:first-child {
    border-top-left-radius: 0;
  }
  &:last-child {
    border-top-right-radius: 0;
  }
`;

// Button styling
const Button = styled.button`
  background-color: #ffc700;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    color: white;
    background-color: #c39904;
  }
`;

const Button2 = styled.button`
  background-color: #444;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    color: white;
    background-color: black;
  }
`;

const SaveButton = styled.button`
  background-color: #5500cc;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px 30px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5500aa;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
`;

const ButtonContainer2 = styled.div`
  display: flex;
  flex-direction: column; /* Change to column direction */
  width: 900px;
  height: 120px;
  margin-top: 30px;
  background-color: #333;
  justify-content: flex-start; /* Corrected typo from flex-first to flex-start */
  border-radius: 10px;
`;

const InputContainer2 = styled.div`
  display: flex; /* Align with text */
  align-items: center;
  margin-left: 22px;
`;

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;

const Text = styled.p`
  margin: 10px 0;
  font-size: 18px;
  margin-left: 10px;
  display: inline-block; /* Make sure it doesn't occupy full width */
  vertical-align: middle; /* Align with input container */
`;

const FileInputLabel = styled.label`
  background-color: #5500cc;
  color: white;
  margin-left: 20px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-block;
  text-align: center;

  &:hover {
    background-color: #5500aa;
  }
`;

const FileNameDisplay = styled.h1`
  margin-left: 20px; /* Space between button and file name display */
  color: black;
  background-color: white; /* Slightly lighter background color */
  padding: 10px 15px; /* Extra padding for better appearance */
  font-size: 15px; /* Slightly smaller font size */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px; /* Adjusted height for better alignment */
`;

const ProgressBar = styled.div`
  background-color: #555;
  border-radius: 20px;
  width: 400px;
  height: 20px;
  margin-left: 30px;
  margin-top: 30px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative; /* Relative positioning for inner text */
`;

const Progress = styled.div`
  background-color: #5500cc;
  height: 100%;
  width: ${(props) => props.width}%;
  transition: width 0.3s;
`;

const ProgressText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  font-weight: bold;
`;

const DetailedTableContainer = styled.div`
  margin-top: 20px;
  padding-top: 20px;
`;

// 추가된 구분선 스타일을 별도의 스타일 컴포넌트로 정의
const Divider = styled.div`
  border-top: 2px solid #555;
  margin-top: -20px;
  margin-bottom: 30px; /* 구분선과 위아래 테이블 사이의 간격 */
`;

const ProjectInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #222;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
`;

const ProjectDetail = styled.p`
  margin: 10px 0;
  margin-left: 10px;
  line-height: 1.8;
`;

const Heading = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #fff;
`;

const Mydatabase = () => {
  const navigate = useNavigate();
  const [activeConnectionIndex, setActiveConnectionIndex] = useState(null);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [databaseConnections, setDatabaseConnections] = useState([]);
  const [detailedData1, setDetailedData1] = useState([]); // State for detailed data from dbtableData
  const [detailedData2, setDetailedData2] = useState([]); // State for detailed data from dbmergeData

  // Fetch the database connections from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchdbData();
        setDatabaseConnections(data); // Set the database connections
        const tableInfo = await fetchdbtableData();
        setDetailedData1(tableInfo); // Set detailed data 1
        const mergedData = await fetchdbmergeData();
        setDetailedData2(mergedData); // Set detailed data 2
      } catch (error) {
        console.error(error.message); // Handle any errors that might occur
      }
    };

    fetchData();
  }, []); // Empty dependency array means this will only run once when the component mounts

  const handleConnect = (index) => {
    setActiveConnectionIndex(index);
  };

  const handleDisconnect = () => {
    setActiveConnectionIndex(null);
  };

  const [progress, setProgress] = useState(0); // Initialize progress to 0
  const [fileName, setFileName] = useState(""); // State to store file name

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name); // 파일 이름 업데이트

      // 파일 업로드 진행 상황 시뮬레이션
      let uploadProgress = 0;
      const interval = setInterval(() => {
        if (uploadProgress < 100) {
          uploadProgress += 5; // 진행률 증가
          setProgress(uploadProgress);
        } else {
          clearInterval(interval);
          setIsUploadComplete(true); // 업로드 완료 시 상태 변경
        }
      }, 200); // 200ms마다 업데이트
    }
    console.log(file); // 데모를 위한 로그
  };

  return (
    <MainContent>
      <Title>나의 DB 리스트</Title>
      <Description>연결된 데이터베이스 정보를 확인하세요.</Description>
      <ProjectInfoContainer>
        <ProjectDetail>
          <Heading> 나의 DB 리스트란?</Heading>
          <br />
          - 추천 시스템을 위한 사용자 데이터와, 아이템 데이터의 MATRIX 를
          애플리케이션 데이터베이스를 사용하여 학습하고 추론 할 수 있게 합니다.
          <br />- 새로운 데이터베이스를 연결하기 위해서는 "추가하기" 를 클릭해
          주세요. <br />- 추가된 DB에 테스트 데이터(CSV 파일)를 업로드 하여 학습
          데이터셋의 트레이닝 데이터 레이아웃을 확인 합니다.
        </ProjectDetail>
      </ProjectInfoContainer>
      <ButtonContainer>
        <SaveButton onClick={() => navigate("/database/add")}>
          추가하기
        </SaveButton>
      </ButtonContainer>
      <WhiteTable>
        <thead>
          <tr>
            <WhiteTableHeader>이름</WhiteTableHeader>
            <WhiteTableHeader>데이터베이스 종류</WhiteTableHeader>
            <WhiteTableHeader>서버 주소</WhiteTableHeader>
            <WhiteTableHeader>포트</WhiteTableHeader>
            <WhiteTableHeader>계정</WhiteTableHeader>
            <WhiteTableHeader>연결 상태</WhiteTableHeader>
          </tr>
        </thead>
        <tbody>
          {databaseConnections.map((db, index) => (
            <WhiteTableRow key={db.name}>
              <WhiteTableCell>{db.name}</WhiteTableCell>
              <WhiteTableCell>{db.type}</WhiteTableCell>
              <WhiteTableCell>{db.server}</WhiteTableCell>
              <WhiteTableCell>{db.port}</WhiteTableCell>
              <WhiteTableCell>{db.account}</WhiteTableCell>
              <WhiteTableCell>
                {activeConnectionIndex === index ? (
                  <Button2 onClick={handleDisconnect}>연결끊기</Button2>
                ) : (
                  <Button onClick={() => handleConnect(index)}>연결하기</Button>
                )}
              </WhiteTableCell>
            </WhiteTableRow>
          ))}
        </tbody>
      </WhiteTable>

      {activeConnectionIndex !== null && (
        <DetailedTableContainer>
          <Title2>
            [{databaseConnections[activeConnectionIndex].name} 보기]
          </Title2>

          <ButtonContainer2>
            <Description2>데이터 추가하기</Description2>
            <div>
              <InputContainer2>
                <Text>CSV 파일:</Text>
                <FileNameDisplay>{fileName || "파일 없음"}</FileNameDisplay>
                <FileInput
                  type="file"
                  id="csvFile"
                  accept=".csv"
                  onChange={handleFileUpload}
                />
                <FileInputLabel htmlFor="csvFile">
                  {isUploadComplete ? "추가하기" : "파일 업로드"}
                </FileInputLabel>

                <ProgressBar>
                  <Progress width={progress} />
                  <ProgressText>{progress}%</ProgressText>
                </ProgressBar>
              </InputContainer2>
            </div>
          </ButtonContainer2>

          <DetailedTable>
            <thead>
              <tr>
                <DetailedTableHeader>User_ID</DetailedTableHeader>
                <DetailedTableHeader>Item_ID</DetailedTableHeader>
                <DetailedTableHeader>Review_Points</DetailedTableHeader>
              </tr>
            </thead>
            <tbody>
              {detailedData1.map((data) => (
                <DetailedTableRow key={data.userId}>
                  <DetailedTableCell>{data.userId}</DetailedTableCell>
                  <DetailedTableCell>{data.itemId}</DetailedTableCell>
                  <DetailedTableCell>{data.reviewPoints}</DetailedTableCell>
                </DetailedTableRow>
              ))}
            </tbody>
          </DetailedTable>

          {isUploadComplete && (
            <DetailedTableContainer>
              <Divider />
              <Title2>{` >> 병합된 ${databaseConnections[activeConnectionIndex].name}`}</Title2>
              <ButtonContainer>
                <SaveButton onClick={() => navigate("/learning")}>
                  저장하기
                </SaveButton>
              </ButtonContainer>
              <DetailedTable>
                <thead>
                  <tr>
                    <DetailedTableHeader>User_ID</DetailedTableHeader>
                    <DetailedTableHeader>Item_ID</DetailedTableHeader>
                    <DetailedTableHeader>Review_Points</DetailedTableHeader>
                  </tr>
                </thead>
                <tbody>
                  {detailedData2.map((data) => (
                    <DetailedTableRow key={data.userId}>
                      <DetailedTableCell>{data.userId}</DetailedTableCell>
                      <DetailedTableCell>{data.itemId}</DetailedTableCell>
                      <DetailedTableCell>{data.reviewPoints}</DetailedTableCell>
                    </DetailedTableRow>
                  ))}
                </tbody>
              </DetailedTable>
            </DetailedTableContainer>
          )}
        </DetailedTableContainer>
      )}
    </MainContent>
  );
};

export default Mydatabase;
